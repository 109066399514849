import React from "react";

const ThumbnailsDescriptionSection = ({
	children,
	id,
	nrItems = 3,
	textAlign = "left",
	title = "",
	title1,
	text1,
	image1,
	title2,
	text2,
	image2,
	title3,
	text3,
	image3,
	title4 = false,
	text4 = false,
	image4 = false,
	hasEffect = false,
	bgColorClass,
	aos_settings,
}) => {
	const renderImages = () => {
		return (
			<React.Fragment>
				<div
					className={`col-12 ${
						nrItems === 3 ? "col-md-4" : "col-md-3"
					} pt-4 pt-md-5`}
				>
					<div
						className={`w-100 ${bgColorClass}`}
						style={{ border: "1px solid black" }}
					>
						<div className="container-img-effect multiply">
							<div className={`${hasEffect ? "img-reveal" : ""}`}>
								<img
									alt=""
									src={image1}
									className="img-remove-blend image-left-to-right w-100"
									style={{
										maxHeight: "300px",
										padding: "50px",
									}}
								/>
							</div>
						</div>
					</div>
					<div className={`pt-3 text-${textAlign}`} {...aos_settings}>
						<h3 className="pb-2">{title1}</h3>
						<p>{text1}</p>
					</div>
				</div>
				<div
					className={`col-12 ${
						nrItems === 3 ? "col-md-4" : "col-md-3"
					} pt-4 pt-md-5`}
				>
					<div
						className={`w-100 ${bgColorClass}`}
						style={{ border: "1px solid black" }}
					>
						<div className="container-img-effect multiply">
							<div className={`${hasEffect ? "img-reveal" : ""}`}>
								<img
									alt=""
									src={image2}
									style={{
										maxHeight: "300px",
										padding: "50px",
									}}
									className="img-remove-blend image-left-to-right w-100"
								/>
							</div>
						</div>
					</div>
					<div className={`pt-3 text-${textAlign}`} {...aos_settings}>
						<h3 className="pb-2">{title2}</h3>
						<p>{text2}</p>
					</div>
				</div>
				<div
					className={`col-12 ${
						nrItems === 3 ? "col-md-4" : "col-md-3"
					} pt-4 pt-md-5`}
				>
					<div
						className={`w-100 ${bgColorClass}`}
						style={{ border: "1px solid black" }}
					>
						<div className="container-img-effect multiply">
							<div className={`${hasEffect ? "img-reveal" : ""}`}>
								<img
									alt=""
									src={image3}
									style={{
										maxHeight: "300px",
										padding: "50px",
									}}
									className="img-remove-blend image-left-to-right w-100"
								/>
							</div>
						</div>
					</div>
					<div className={`pt-3 text-${textAlign}`} {...aos_settings}>
						<h3 className="pb-2">{title3}</h3>
						<p>{text3}</p>
					</div>
				</div>
				{nrItems === 4 ? (
					<div className={`col-12 col-md-3 pt-4 pt-md-5`}>
						<div
							className={`w-100 ${bgColorClass}`}
							style={{ border: "1px solid black" }}
						>
							<div className="container-img-effect multiply">
								<div
									className={`${
										hasEffect ? "img-reveal" : ""
									}`}
								>
									<img
										alt=""
										src={image4}
										className="img-remove-blend image-left-to-right w-100"
										style={{
											maxHeight: "300px",
											padding: "50px",
										}}
									/>
								</div>
							</div>
						</div>
						<div
							className={`pt-3 text-${textAlign}`}
							{...aos_settings}
						>
							<h3 className="pb-2">{title4}</h3>
							<p>{text4}</p>
						</div>
					</div>
				) : (
					""
				)}
			</React.Fragment>
		);
	};
	return (
		<React.Fragment>
			<div
				className="row pt-4 pt-md-5 pr-3 pl-3 pr-md-5 pl-md-5 h-100 "
				id={id}
			>
				<div className="col-12">
					<h1 {...aos_settings}>{title}</h1>
				</div>
			</div>
			<div
				className="row pr-5 pl-5 pb-4 pb-md-5 h-100 justify-content-start align-items-stretch"
				id={id}
			>
				{renderImages()}
			</div>
		</React.Fragment>
	);
};

export default ThumbnailsDescriptionSection;
