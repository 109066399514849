import React from "react";
import Slider from "react-slick";
import "./SimpleSlider.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SimpleSlider = ({ children, images }) => {
	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
	};

	const renderSlides = () => {
		return images.map((img, i) => (
			<div key={i}>
				<img src={img} className="img-remove-blend w-100" alt="" />
			</div>
		));
	};
	return (
		<React.Fragment>
			<div className="container-img-effect">
				<div className="img-reveal">
					<Slider
						className="slideshow--custom-arrow image-left-to-right"
						{...settings}
					>
						{renderSlides()}
					</Slider>
				</div>
			</div>
		</React.Fragment>
	);
};

export default SimpleSlider;
