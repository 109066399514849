import React from "react";
import "./ProductSection.css";

import "photoswipe/dist/photoswipe.css";
import "photoswipe/dist/default-skin/default-skin.css";

import PhotoswipeUIDefault from "photoswipe/dist/photoswipe-ui-default";
import { CustomGallery, Item, DefaultLayout } from "react-photoswipe-gallery";

const ProductSection = ({
	children,
	id,
	title,
	titleColorClass,
	description = false,
	constructionsTitle,
	constructions,
	image,
	otherImages,
	aos_settings,
}) => {
	const layoutRef = React.useRef();

	return (
		<React.Fragment>
			<div className="row pt-4 pt-md-5 pr-3 pl-3 pr-md-5 pl-md-5">
				<div className="col-12">
					<h1 {...aos_settings} className={titleColorClass}>
						{title}
					</h1>
					<p {...aos_settings} className="subtitle">
						{description ? description : ""}
					</p>
				</div>
			</div>
			<div
				className="row pr-3 pl-3 pr-md-5 pl-md-5 pb-4 pb-md-5 justify-content-between"
				id={id}
			>
				<div className="col-12 col-md-5 pt-4 pt-md-5">
					<div className="w-100 h-100">
						<div className="container-img-effect">
							<div className="img-reveal">
								<CustomGallery
									layoutRef={layoutRef}
									ui={PhotoswipeUIDefault}
								>
									<Item
										original={image}
										thumbnail={image}
										width="1024"
										height="768"
										style={{
											mixBlendMode: "multiply",
											cursor: "pointer",
											transition:
												"mix-blend-mode 0.5s ease-in-out",
										}}
									>
										{({ ref, open }) => (
											<img
												ref={ref}
												onClick={open}
												src={image}
												alt=""
												className="img-remove-blend image-left-to-right"
											/>
										)}
									</Item>
									<Item
										original={otherImages}
										thumbnail={otherImages}
										width="1024"
										height="768"
									>
										{({ ref, open }) => (
											<img
												ref={ref}
												onClick={open}
												src={otherImages}
												alt=""
												style={{ display: "none" }}
											/>
										)}
									</Item>
								</CustomGallery>
							</div>
						</div>
						<DefaultLayout
							shareButton={false}
							fullscreenButton={false}
							zoomButton={false}
							ref={layoutRef}
						/>
					</div>
				</div>
				<div className="col-12 col-md-6 col-sm-12 pt-4 pt-md-5">
					<div>
						<h3 {...aos_settings} className="pb-3">
							Características
						</h3>
						<p {...aos_settings} style={{ maxWidth: "80ch" }}>
							{children}
						</p>
					</div>

					<div className="d-flex pb-4 pb-md-5">
						<div className="pt-4 pt-md-5">
							<h3 {...aos_settings} className="pb-3">
								{constructionsTitle}
							</h3>
							<p {...aos_settings} style={{ maxWidth: "65ch" }}>
								{constructions}
							</p>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default ProductSection;
