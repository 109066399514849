import React from "react";
import "./Hero.css";

import { useTranslation } from "react-i18next";

import bannerImage from "../assets/agropecuaria/banner.jpg";
import image1 from "../assets/agropecuaria/image1.jpg";
import image2 from "../assets/agropecuaria/image2.jpg";
import image3 from "../assets/agropecuaria/image3.jpg";
import image4 from "../assets/agropecuaria/image4.jpg";
import image5 from "../assets/agropecuaria/image5.jpg";
import image6 from "../assets/agropecuaria/image6.jpg";
import image7 from "../assets/agropecuaria/image7.jpg";
// import desenhoTecnicoSmall1 from "../assets/tre3.png";
// import desenhoTecnicoSmall2 from "../assets/quatro4.png";
// import desenhoTecnicoBig from "../assets/um1.png";
import desenhoTecnicoSmall1 from "../assets/agropecuaria/agrodetail.jpg";
import desenhoTecnicoSmall2 from "../assets/agropecuaria/agrodetail2.jpg";
import desenhoTecnicoBig from "../assets/agropecuaria/agrodetail3.jpg";
import fichaTecnicaFile from "../assets/agropecuaria/agropecuaria.pdf";

import Banner from "../components/sections/Banner";
import GallerySection from "../components/sections/GallerySection";
import ThreeImageSeparator from "../components/sections/ThreeImageSeparator";
import TitleSection from "../components/sections/TitleSection";
import PageWrapper from "./PageWrapper";
import BulletSection from "../components/sections/BulletSection";

const Agropecuaria = ({ children, aos_settings }) => {
	const { t } = useTranslation();
	return (
		<PageWrapper bgColorClass="bc-grey-estruturas pb-4 pb-md-5">
			<Banner isVideo={false} bannerImage={bannerImage} logoFill="white">
				{t("agropecuaria.agropecuaria")}
			</Banner>
			<div className="page-content c-grey">
				<TitleSection
					id="equipamentos-de-apoio"
					title={t("agropecuaria.equipamentos-de-apoio")}
					titleColorClass="c-grey"
					smallTitle=""
					smallTitleColorClass=""
					buttonLink={fichaTecnicaFile}
					buttonText={t("actions.download-brochura")}
					aos_settings={aos_settings}
				/>

				<BulletSection
					title={t("general.caracteristicas")}
					aos_settings={aos_settings}
				>
					<div className="col-12 col-md-4 pl-0">
						<ul>
							<li {...aos_settings}>
								{t(
									"agropecuaria.equipamentos-de-apoio-texto-1"
								)}
							</li>
							<li {...aos_settings}>
								{t(
									"agropecuaria.equipamentos-de-apoio-texto-2"
								)}
							</li>
						</ul>
					</div>
					<div className="col-12 col-md-4">
						<ul>
							<li {...aos_settings}>
								{t(
									"agropecuaria.equipamentos-de-apoio-texto-3"
								)}
							</li>
							<li {...aos_settings}>
								{t(
									"agropecuaria.equipamentos-de-apoio-texto-4"
								)}
							</li>
							<li {...aos_settings}>
								{t(
									"agropecuaria.equipamentos-de-apoio-texto-5"
								)}
							</li>
						</ul>
					</div>
					<div className="col-12 col-md-4">
						<ul>
							<li {...aos_settings}>
								{t(
									"agropecuaria.equipamentos-de-apoio-texto-6"
								)}
							</li>
							<li {...aos_settings}>
								{t(
									"agropecuaria.equipamentos-de-apoio-texto-7"
								)}
							</li>
						</ul>
					</div>
				</BulletSection>

				<GallerySection
					id="agropecuaria-galeria"
					noTitle={true}
					images={[
						image7,
						image1,
						image4,
						image2,
						image3,
						image5,
						image6,
					]}
					aos_settings={aos_settings}
				></GallerySection>

				<TitleSection
					id="abrigo-animal"
					title={t("agropecuaria.abrigo-animal")}
					titleColorClass="c-grey"
					smallTitle={t("agropecuaria.abrigo-animal-descricao")}
					smallTitleColorClass="c-grey"
					buttonLink={fichaTecnicaFile}
					buttonText={t("actions.download-brochura")}
					aos_settings={aos_settings}
				/>

				<BulletSection
					title={t("general.caracteristicas")}
					aos_settings={aos_settings}
				>
					<div className="col-12 col-md-6 pl-0">
						<ul>
							<li {...aos_settings}>
								{t("agropecuaria.abrigo-animal-texto-1")}
							</li>
							<li {...aos_settings}>
								{t("agropecuaria.abrigo-animal-texto-2")}
							</li>
							<li {...aos_settings}>
								{t("agropecuaria.abrigo-animal-texto-3")}
							</li>
							<li {...aos_settings}>
								{t("agropecuaria.abrigo-animal-texto-4")}
							</li>
							<li {...aos_settings}>
								{t("agropecuaria.abrigo-animal-texto-5")}
							</li>
							<li {...aos_settings}>
								{t("agropecuaria.abrigo-animal-texto-6")}
							</li>
						</ul>
					</div>
					<div className="col-12 col-md-6">
						<ul>
							<li {...aos_settings}>
								{t("agropecuaria.abrigo-animal-texto-7")}
							</li>
							<li {...aos_settings}>
								{t("agropecuaria.abrigo-animal-texto-8")}
							</li>
							<li {...aos_settings}>
								{t("agropecuaria.abrigo-animal-texto-9")}
							</li>
							<li {...aos_settings}>
								{t("agropecuaria.abrigo-animal-texto-10")}
								<ul>
									<li {...aos_settings}>
										{t(
											"agropecuaria.abrigo-animal-texto-10-1"
										)}
									</li>
									<li {...aos_settings}>
										{t(
											"agropecuaria.abrigo-animal-texto-10-2"
										)}
									</li>
									<li {...aos_settings}>
										{t(
											"agropecuaria.abrigo-animal-texto-10-3"
										)}
									</li>
									<li {...aos_settings}>
										{t(
											"agropecuaria.abrigo-animal-texto-10-4"
										)}
									</li>
								</ul>
							</li>
						</ul>
					</div>
				</BulletSection>

				<ThreeImageSeparator
					id="desenho-tecnico"
					isInline={true}
					imageSmall1={desenhoTecnicoSmall1}
					imageSmall2={desenhoTecnicoSmall2}
					imageBig={desenhoTecnicoBig}
					aos_settings={aos_settings}
					bgColorClass="bc-grey-estruturas"
				/>
			</div>
		</PageWrapper>
	);
};

export default Agropecuaria;
