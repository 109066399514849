import React from "react";
import "./Hero.css";

import { useTranslation } from "react-i18next";

import Banner from "../components/sections/Banner";
import ProductSection from "../components/sections/ProductSection";
import ImageWithLegend from "../components/sections/ImageWithLegend";
import ImageSeparator from "../components/sections/ImageSeparator";
import PageWrapper from "./PageWrapper";
import TitleSection from "../components/sections/TitleSection";

//imagens
import bannerImage from "../assets/estufas/banner_1.jpg";
import imageSeparator1 from "../assets/estufas/banner_2.jpg";
import imageSeparator2 from "../assets/estufas/banner_3.jpg";
import imageSeparator3 from "../assets/estufas/banner_4.jpg";
import rectaPlantaTecnico from "../assets/rectaPlanta.png";
import rectaPlanta from "../assets/estufas/recta_planta.jpg";
import rectaPlantaGotica from "../assets/estufas/gotica.jpg";
import rectaPlantaTropical from "../assets/estufas/tropical.jpg";
import rectaFungi from "../assets/estufas/fungi.jpg";
import estufasApoio from "../assets/estufas/apoio.jpg";
import bancadasModulares from "../assets/estufas/bancada.jpg";
// import banner_1 from "../assets/estufas/banner_1.jpg";
// import banner_2 from "../assets/estufas/banner_2.jpg";
// import banner_3 from "../assets/estufas/banner_3.jpg";
// import banner_4 from "../assets/estufas/banner_4.jpg";
import rectaFlor from "../assets/estufas/flor.jpg";
import gardenCenter from "../assets/estufas/garden.jpg";
// import gotica from "../assets/estufas/gotica.jpg";
import hortoJardim from "../assets/estufas/horto.jpg";
import macrotunel from "../assets/estufas/macrotunel.jpg";
// import tropical from "../assets/estufas/tropical.jpg";
import tunel from "../assets/estufas/tunel.jpg";

const Estufas = ({ children, aos_settings }) => {
	React.useEffect(() => {
		const imageList = [
			bannerImage,
			imageSeparator1,
			imageSeparator2,
			imageSeparator3,
			rectaPlantaTecnico,
			rectaPlanta,
			rectaFungi,
			estufasApoio,
			bancadasModulares,
			rectaFlor,
			gardenCenter,
			hortoJardim,
			macrotunel,
			tunel,
		];
		imageList.forEach((image) => {
			const newImage = new Image();
			newImage.src = image;
			window[image] = newImage;
		});
	}, []);
	const { t } = useTranslation();
	return (
		<PageWrapper bgColorClass="bc-grey-estufas pb-4 pb-md-5">
			<Banner isVideo={false} bannerImage={bannerImage} logoFill="white">
				{t("estufas.estufas")}
			</Banner>
			<div className="page-content">
				<TitleSection
					id="desenho-tecnico"
					title={t("estufas.desenho-tecnico")}
					titleColorClass="c-grey"
					smallTitle=""
					smallTitleColorClass=""
					buttonLink={rectaPlantaTecnico}
					buttonText={t("actions.download-brochura")}
					aos_settings={aos_settings}
				/>
				<ImageWithLegend
					aos_settings={aos_settings}
					image={rectaPlantaTecnico}
					legend={
						<React.Fragment>
							<ol
								className="ol-custom ol-c-green"
								{...aos_settings}
							>
								<li>{t("estufas.desenho-tecnico-texto-1")}</li>
								<li>{t("estufas.desenho-tecnico-texto-2")}</li>
								<li>{t("estufas.desenho-tecnico-texto-3")}</li>
								<li>{t("estufas.desenho-tecnico-texto-4")}</li>
								<li>{t("estufas.desenho-tecnico-texto-5")}</li>
								<li>{t("estufas.desenho-tecnico-texto-6")}</li>
								<li>{t("estufas.desenho-tecnico-texto-7")}</li>
							</ol>
						</React.Fragment>
					}
				/>

				<ProductSection
					id="recta-planta"
					title={t("estufas.recta-planta")}
					titleColorClass="c-dark-green"
					description={
						<React.Fragment>
							{t("estufas.recta-planta-descricao-1")}
							<br /> {t("estufas.recta-planta-descricao-2")}
						</React.Fragment>
					}
					constructionsTitle={t("general.construcoes-padrao")}
					constructions={
						<React.Fragment>
							{t("estufas.recta-planta-construcoes-1")}
							<br /> {t("estufas.recta-planta-construcoes-2")}
						</React.Fragment>
					}
					image={rectaPlanta}
					otherImages={rectaPlantaTecnico}
					aos_settings={aos_settings}
				>
					{t("estufas.recta-planta-texto")}
				</ProductSection>

				<ProductSection
					id="recta-planta-gotica"
					title={t("estufas.recta-planta-gotica")}
					titleColorClass="c-dark-green"
					description={
						<React.Fragment>
							{t("estufas.recta-planta-gotica-descricao-1")}
							<br />
							{t("estufas.recta-planta-gotica-descricao-2")}
						</React.Fragment>
					}
					constructionsTitle={t("general.construcoes-padrao")}
					constructions={
						<React.Fragment>
							{t("estufas.recta-planta-gotica-construcoes-1")}
							<br />
							{t("estufas.recta-planta-gotica-construcoes-2")}
						</React.Fragment>
					}
					image={rectaPlantaGotica}
					otherImages={rectaPlantaTecnico}
					aos_settings={aos_settings}
				>
					{t("estufas.recta-planta-gotica-texto")}
				</ProductSection>

				<ProductSection
					id="recta-planta-tropical"
					title={t("estufas.recta-planta-tropical")}
					titleColorClass="c-dark-green"
					description={
						<React.Fragment>
							{t("estufas.recta-planta-tropical-descricao-1")}
							<br />
							{t("estufas.recta-planta-tropical-descricao-2")}
						</React.Fragment>
					}
					constructionsTitle={t("general.construcoes-padrao")}
					constructions={
						<React.Fragment>
							{t("estufas.recta-planta-tropical-construcoes-1")}
						</React.Fragment>
					}
					image={rectaPlantaTropical}
					otherImages={rectaPlantaTecnico}
					aos_settings={aos_settings}
				>
					{t("estufas.recta-planta-gotica-texto")}
				</ProductSection>

				<ImageSeparator image={imageSeparator1} />

				<ProductSection
					id="recta-fungi"
					title={t("estufas.recta-fungi")}
					titleColorClass="c-dark-green"
					description={
						<React.Fragment>
							{t("estufas.recta-fungi-descricao-1")}
							<br />
							{t("estufas.recta-fungi-descricao-2")}
						</React.Fragment>
					}
					constructionsTitle={t("general.construcoes-padrao")}
					constructions={
						<React.Fragment>
							{t("estufas.recta-fungi-construcoes-1")}
							<br />
							{t("estufas.recta-fungi-construcoes-2")}
						</React.Fragment>
					}
					image={rectaFungi}
					otherImages={rectaFungi}
					aos_settings={aos_settings}
				>
					{t("estufas.recta-fungi-texto")}
				</ProductSection>

				<ProductSection
					id="recta-flor"
					title={t("estufas.recta-flor")}
					titleColorClass="c-dark-green"
					description={
						<React.Fragment>
							{t("estufas.recta-flor-descricao-1")}
							<br />
							{t("estufas.recta-flor-descricao-2")}
						</React.Fragment>
					}
					constructionsTitle={t("general.construcoes-padrao")}
					constructions={
						<React.Fragment>
							{t("estufas.recta-flor-construcoes-1")}
							<br />
							{t("estufas.recta-flor-construcoes-2")}
						</React.Fragment>
					}
					image={rectaFlor}
					otherImages={rectaFlor}
					aos_settings={aos_settings}
				>
					{t("estufas.recta-flor-texto")}
				</ProductSection>

				<ProductSection
					id="garden-center"
					title={t("estufas.garden-center")}
					titleColorClass="c-dark-green"
					description={
						<React.Fragment>
							{t("estufas.garden-center-descricao-1")}
							<br />
							{t("estufas.garden-center-descricao-2")}
						</React.Fragment>
					}
					constructionsTitle={t("general.construcoes-padrao")}
					constructions={
						<React.Fragment>
							{t("estufas.garden-center-construcoes-1")}
							<br />
							{t("estufas.garden-center-construcoes-2")}
						</React.Fragment>
					}
					image={gardenCenter}
					otherImages={gardenCenter}
					aos_settings={aos_settings}
				>
					{t("estufas.garden-center-texto")}
				</ProductSection>

				<ImageSeparator image={imageSeparator2} />

				<ProductSection
					id="horto-jardim"
					title={t("estufas.horto-jardim")}
					titleColorClass="c-dark-green"
					description={
						<React.Fragment>
							{t("estufas.horto-jardim-descricao-1")}
							<br />
							{t("estufas.horto-jardim-descricao-2")}
						</React.Fragment>
					}
					constructionsTitle={t("general.construcoes-padrao")}
					constructions={
						<React.Fragment>
							{t("estufas.horto-jardim-construcoes-1")}
						</React.Fragment>
					}
					image={hortoJardim}
					otherImages={hortoJardim}
					aos_settings={aos_settings}
				>
					{t("estufas.horto-jardim-texto")}
				</ProductSection>

				<ProductSection
					id="tunel"
					title={t("estufas.tunel")}
					titleColorClass="c-dark-green"
					description={
						<React.Fragment>
							{t("estufas.tunel-descricao-1")}
							<br />
							{t("estufas.tunel-descricao-2")}
						</React.Fragment>
					}
					constructionsTitle={t("general.construcoes-padrao")}
					constructions={
						<React.Fragment>
							{t("estufas.tunel-construcoes-1")}
							<br />
							{t("estufas.tunel-construcoes-2")}
						</React.Fragment>
					}
					image={tunel}
					otherImages={tunel}
					aos_settings={aos_settings}
				>
					{t("estufas.tunel-texto")}
				</ProductSection>

				<ProductSection
					id="macrotunel"
					title={t("estufas.macrotunel")}
					titleColorClass="c-dark-green"
					description={
						<React.Fragment>
							{t("estufas.macrotunel-descricao-1")}
							<br />
							{t("estufas.macrotunel-descricao-2")}
						</React.Fragment>
					}
					constructionsTitle={t("general.construcoes-padrao")}
					constructions={
						<React.Fragment>
							{t("estufas.macrotunel-construcoes-1")}
						</React.Fragment>
					}
					image={macrotunel}
					otherImages={macrotunel}
					aos_settings={aos_settings}
				>
					{t("estufas.macrotunel-texto")}
				</ProductSection>

				<ImageSeparator image={imageSeparator3} />

				<ProductSection
					id="estufas-de-apoio"
					title={t("estufas.estufas-de-apoio")}
					titleColorClass="c-dark-green"
					description={
						<React.Fragment>
							{t("estufas.estufas-de-apoio-descricao-1")}
							<br />
							{t("estufas.estufas-de-apoio-descricao-2")}
						</React.Fragment>
					}
					constructionsTitle={t("general.construcoes-padrao")}
					constructions={
						<React.Fragment>
							{t("estufas.estufas-de-apoio-construcoes-1")}
							<br />
							{t("estufas.estufas-de-apoio-construcoes-2")}
						</React.Fragment>
					}
					image={estufasApoio}
					otherImages={estufasApoio}
					aos_settings={aos_settings}
				>
					{t("estufas.estufas-de-apoio-texto")}
				</ProductSection>

				<ProductSection
					id="bancadas-modulares"
					title={t("estufas.bancadas-modulares")}
					titleColorClass="c-dark-green"
					description={
						<React.Fragment>
							{t("estufas.bancadas-modulares-descricao-1")}
							<br />
							{t("estufas.bancadas-modulares-descricao-2")}
						</React.Fragment>
					}
					constructionsTitle={t("general.construcoes-padrao")}
					constructions={
						<React.Fragment>
							{t("estufas.bancadas-modulares-construcoes-1")}
							<br />
							{t("estufas.bancadas-modulares-construcoes-2")}
						</React.Fragment>
					}
					image={bancadasModulares}
					otherImages={bancadasModulares}
					aos_settings={aos_settings}
				>
					{t("estufas.bancadas-modulares-texto")}
				</ProductSection>
			</div>
		</PageWrapper>
	);
};

export default Estufas;
