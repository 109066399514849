import React from "react";
import "./WeirdSection.css";

const BulletSection = ({
	children,
	id,
	title,
	titleColorClass,
	description,
	constructionsTitle,
	constructions,
	imageSmall,
	imageBig,
	fichaTecnicaFile,
	fichaTecnicaFileText,
}) => {
	return (
		<React.Fragment>
			<div
				className="row pt-4 pt-md-5 pr-3 pl-3 pr-md-5 pl-md-5 h-100 justify-content-between align-items-stretch"
				id={id}
			>
				<div className="col-12">
					<h3>{title}:</h3>
				</div>
			</div>
			<div
				className="row pt-4 pr-3 pl-3 pr-md-5 pl-md-5 pb-4 pb-md-5 h-100 justify-content-between align-items-stretch"
				id={id}
			>
				{children}
			</div>
		</React.Fragment>
	);
};

export default BulletSection;
