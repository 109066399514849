import React from "react";
import { ReactComponent as ReactLogo } from "../../assets/logos/logo_em_black.svg";
import { Link } from "react-router-dom";

// import VideoJs from "../videojs/VideoJs";

const Banner = ({
	children,
	isVideo,
	bannerImage,
	videoSrc = null,
	logoFill,
}) => {
	const renderVideoBanner = () => {
		// <VideoJs
		// 			className="m-0 p-0"
		// 			autoPlay
		// 			loop
		// 			muted
		// 			style={{
		// 				position: "absolute",
		// 				objectFit: "cover",
		// 				objectPosition: "center",
		// 				height: "100vh",
		// 				width: "100vw",
		// 				position: "fix",
		// 				top: "0",
		// 				left: "0",
		// 				padding: "0",
		// 				minWidth: "100%",
		// 				minHeight: "100%",
		// 			}}
		// 			poster={bannerImage}
		// 			videoSrc={videoSrc}
		// 		></VideoJs>
		return (
			<div
				className="m-0 p-0"
				style={{
					position: "relative",
					overflow: "hidden",
					width: "100%",
					height: "100vh",
				}}
			>
				<video
					className="m-0 p-0"
					autoPlay
					loop
					muted
					style={{
						position: "absolute",
						objectFit: "cover",
						objectPosition: "center",
						height: "100vh",
						width: "100vw",
						top: "0",
						left: "0",
						padding: "0",
						minWidth: "100%",
						minHeight: "100%",
					}}
					poster={bannerImage}
				>
					<source src={videoSrc} type="video/mp4" />
				</video>
				<div
					className="w-100 h-100 m-0 p-0"
					style={{ position: "absolute", left: "0", top: "0" }}
				>
					<div
						className="container-fluid h-100"
						style={{ backgroundColor: "#00000055" }}
					>
						<div className="h-100 d-flex flex-column align-items-start justify-content-center banner-padding">
							<ReactLogo
								fill={logoFill}
								style={{
									alignSelf: "flex-start",
									maxWidth: "80px",
									height: "auto",
								}}
								className="EM-logo"
							/>
							<div
								style={{
									margin: "auto 0",
								}}
							>
								{children}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};

	const renderImageBanner = () => {
		return (
			<div
				className="w-100 m-0 p-0 banner banner-effect"
				style={{
					backgroundImage: `url("${bannerImage}")`,
					backgroundRepeat: "no-repeat",
					backgroundSize: "cover",
					backgroundPosition: "center center",
					height: "70vh",
				}}
			>
				<div
					className="container-fluid h-100"
					style={{ backgroundColor: "#0000004A" }}
				>
					<div className="h-100 d-flex flex-column align-items-center justify-content-center banner-padding">
						<Link
							to="/"
							style={{
								alignSelf: "flex-start",
							}}
						>
							<ReactLogo
								fill={logoFill}
								style={{
									maxWidth: "80px",
									height: "auto",
								}}
								className="EM-logo"
							/>
						</Link>
						<h1
							className="c-white banner-title"
							style={{
								margin: "auto",
							}}
						>
							{children}
						</h1>
					</div>
				</div>
			</div>
		);
	};

	const renderHtml = () => {
		if (isVideo) {
			return renderVideoBanner();
		}
		return renderImageBanner();
	};

	return renderHtml();
};

export default Banner;
