import React from "react";
import "./WeirdSection.css";
import "../elements/TitlePlusButton";

import TitlePlusButton from "../elements/TitlePlusButton";

const TitleSection = ({
	children,
	id,
	noHeight100 = false,
	title,
	titleColorClass,
	titleH3 = false,
	smallTitle = false,
	smallTitleColorClass = false,
	customContent = false,
	buttonLink,
	buttonText,
	aos_settings,
}) => {
	return (
		<React.Fragment>
			<div
				className={`row pt-4 pt-md-5 pr-3 pr-md-0 pl-3 pl-md-5 pb-4 pb-md-5 ${
					noHeight100 ? "" : "h-100"
				}`}
				id={id}
			>
				<div className="col-12 pt-4 pt-md-5 pb-4 pb-md-5">
					<TitlePlusButton
						title={title}
						titleColorClass={titleColorClass}
						titleH3={titleH3}
						buttonLink={buttonLink}
						buttonText={buttonText}
						isDownload={true}
						smallTitle={smallTitle}
						smallTitleColorClass={smallTitleColorClass}
						customContent={customContent}
						aos_settings={aos_settings}
					/>
				</div>
			</div>
		</React.Fragment>
	);
};

export default TitleSection;
