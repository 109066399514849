import React from "react";
import "./Hero.css";
import { useTranslation } from "react-i18next";

import bannerImage from "../assets/sobreNos/banner.jpg";
import imageConstrucoes1 from "../assets/sobreNos/image2_new.jpg";
import imageConstrucoes2 from "../assets/sobreNos/image1.jpg";
import Banner from "../components/sections/Banner";
import WeirdSection from "../components/sections/WeirdSection";
import PageWrapper from "./PageWrapper";
import TitleSection from "../components/sections/TitleSection";
import { ReactComponent as Esquema } from "../assets/sobreNos/esquema.svg";

const SobreNos = ({ children, aos_settings }) => {
	const { t } = useTranslation();
	return (
		<PageWrapper bgColorClass="bc-grey-estruturas pb-4 pb-md-5">
			<Banner isVideo={false} bannerImage={bannerImage} logoFill="white">
				{t("sobre-nos.sobre-nos")}
			</Banner>
			<div className="page-content">
				<TitleSection
					id="recta-planta"
					title={
						<React.Fragment>
							{t("home.projectamos-espacos")}, <br />
							{t("home.concebemos-solucoes-de-futuro")}
						</React.Fragment>
					}
					titleColorClass="c-grey"
					smallTitle={
						<React.Fragment>
							{t("sobre-nos.sobre-nos-texto")}
							<br />
							<ul className="pt-4">
								<li {...aos_settings}>
									{t("sobre-nos.sobre-nos-texto-point-1")}
								</li>
								<li {...aos_settings}>
									{t("sobre-nos.sobre-nos-texto-point-2")}
								</li>
								<li {...aos_settings}>
									{t("sobre-nos.sobre-nos-texto-point-3")}
								</li>
							</ul>
						</React.Fragment>
					}
					smallTitleColorClass="c-grey"
					aos_settings={aos_settings}
				/>
				<WeirdSection
					id="recta-planta"
					title={t("sobre-nos.missao")}
					titleColorClass="c-grey"
					titleH3={true}
					firstColWide={true}
					positionSticky={false}
					smallTitle={
						<React.Fragment>
							{t("sobre-nos.missao-texto")}
						</React.Fragment>
					}
					smallTitleColorClass="c-grey"
					imageSmall={imageConstrucoes1}
					imageBig={imageConstrucoes2}
					aos_settings={aos_settings}
					customExtraContent={
						<React.Fragment>
							<div className="row pt-4 pt-md-5">
								<div className="col-12">
									<h4 className="pb-4 pb-md-5">
										{t("sobre-nos.politica-e-objetivos")}
									</h4>
									<p
										className="pr-5"
										style={{ maxWidth: "80ch" }}
									>
										{t(
											"sobre-nos.politica-e-objetivos-texto-1"
										)}
										<br />
										<br />
										{t(
											"sobre-nos.politica-e-objetivos-texto-2"
										)}
										<br />
										{t(
											"sobre-nos.politica-e-objetivos-texto-3"
										)}
										<br />
										{t(
											"sobre-nos.politica-e-objetivos-texto-4"
										)}
										<br />
										{t(
											"sobre-nos.politica-e-objetivos-texto-5"
										)}
									</p>
								</div>
							</div>
						</React.Fragment>
					}
				/>
				<div className="row pt-0 pt-md-5 pr-3 pr-md-0 pl-3 pl-md-5 pb-4 pb-md-5">
					<Esquema style={{ maxWidth: "95%", margin: "0 auto" }} />
				</div>
			</div>
		</PageWrapper>
	);
};

export default SobreNos;
