import React from "react";

const ThreeImageSeparator = ({
	children,
	id,
	isInline = false,
	imageSmall1,
	imageSmall2,
	imageBig,
	hasEffect = false,
	bgColorClass,
	aos_settings,
}) => {
	const renderImages = () => {
		return isInline ? (
			<React.Fragment>
				<div className="col-12 col-md-4 pt-4 pt-md-5">
					<div
						className={`w-100 h-100 ${bgColorClass}`}
						style={{ border: "1px solid black" }}
					>
						<div className="container-img-effect multiply">
							<div className={`${hasEffect ? "img-reveal" : ""}`}>
								<img
									alt=""
									src={imageSmall1}
									className="img-remove-blend image-left-to-right w-100"
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="col-12 col-md-4 pt-4 pt-md-5">
					<div
						className={`w-100 h-100 ${bgColorClass}`}
						style={{ border: "1px solid black" }}
					>
						<div className="container-img-effect multiply">
							<div className={`${hasEffect ? "img-reveal" : ""}`}>
								<img
									alt=""
									src={imageSmall2}
									className="img-remove-blend image-left-to-right w-100"
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="col-12 col-md-4 pt-4 pt-md-5">
					<div
						className={`w-100 h-100 ${bgColorClass}`}
						style={{ border: "1px solid black" }}
					>
						<div className="container-img-effect multiply">
							<div className={`${hasEffect ? "img-reveal" : ""}`}>
								<img
									alt=""
									src={imageBig}
									className="img-remove-blend image-left-to-right w-100"
								/>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		) : (
			<React.Fragment>
				<div className="col-12 col-md-4 pt-4 pt-md-5">
					<div className="h-100 d-flex flex-column justify-content-between align-items-stretch">
						<div
							className={`w-100 ${bgColorClass}`}
							style={{ border: "1px solid black" }}
						>
							<div className="container-img-effect multiply">
								<div
									className={`${
										hasEffect ? "img-reveal" : ""
									}`}
								>
									<img
										alt=""
										src={imageSmall1}
										className="img-remove-blend image-left-to-right w-100"
									/>
								</div>
							</div>
						</div>
						<br />
						<br />
						<div
							className={`w-100 ${bgColorClass}`}
							style={{ border: "1px solid black" }}
						>
							<div className="container-img-effect multiply">
								<div
									className={`${
										hasEffect ? "img-reveal" : ""
									}`}
								>
									<img
										alt=""
										src={imageSmall2}
										className="img-remove-blend image-left-to-right w-100"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-12 col-md-7 col-sm-12 pt-4 pt-md-5">
					<div
						className={`h-100 ${bgColorClass}`}
						style={{ border: "1px solid black" }}
					>
						<div className="container-img-effect h-100 multiply">
							<div
								className={`${
									hasEffect
										? "img-reveal w-100 h-100"
										: "w-100 h-100"
								}`}
							>
								<img
									alt=""
									src={imageBig}
									className="img-remove-blend image-left-to-right w-100 h-100"
								/>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	};
	return (
		<div
			className="row pt-4 pt-md-5 pr-3 pl-3 pr-md-5 pl-md-5 pb-4 pb-md-5 h-100 justify-content-between align-items-stretch"
			id={id}
		>
			{renderImages()}
		</div>
	);
};

export default ThreeImageSeparator;
