import React, { useRef } from "react";
import { TransitionGroup, Transition } from "react-transition-group";
import { Switch, Route } from "react-router-dom";
import { gsap } from "gsap";
import Header from "../components/header/Header";
import Home from "../pages/Home";
import Estufas from "../pages/Estufas";
import ConstrucoesMetalicas from "../pages/ConstrucoesMetalicas";
import PerfisMetalicos from "../pages/PerfisMetalicos";
import Corte from "../pages/Corte";
import Contactos from "../pages/Contactos";
import Agropecuaria from "../pages/Agropecuaria";
import SobreNos from "../pages/SobreNos";
import Privacidade from "../pages/Privacidade";
import Termos from "../pages/Termos";

import CookieConsent from "react-cookie-consent";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Fotovoltaicas from "../pages/Fotovoltaicas";
// import ComingSoon from "../pages/ComingSoon";

//Trying to do lazy loading pages but seems glitchy

// const Home = lazy(() => import("../pages/Home"));
// const Estufas = lazy(() => import("../pages/Estufas"));
// const ConstrucoesMetalicas = lazy(() =>
// 	import("../pages/ConstrucoesMetalicas")
// );
// const Estruturas = lazy(() => import("../pages/Estruturas"));
// const Corte = lazy(() => import("../pages/Corte"));

// const Contactos = lazy(() => import("../pages/Contactos"));
// const Agropecuaria = lazy(() => import("../pages/Agropecuaria"));
// const PerfisMetalicos = lazy(() => import("../pages/PerfisMetalicos"));
// const SobreNos = lazy(() => import("../pages/SobreNos"));

// const getAnchor = () => {
// 	var currentUrl = document.URL,
// 		urlParts = currentUrl.split("#");

// 	return urlParts.length > 1 ? urlParts[1] : null;
// };

const completeCall = (target, parent) => {
	target &&
		gsap.set(target, {
			clearProps: "position, width, transform, top, left",
		});
	parent &&
		gsap.set(parent, {
			clearProps: "transform, overflow, position, min-height",
		});
	target && gsap.set(target, { clearProps: "transform, overflow" });
	// let anchor = getAnchor();
	// if (anchor) {
	// 	var anchor1 = document.getElementById(anchor);
	// 	var posTop = anchor1.offsetTop;

	// 	gsap.to(window, 1, { scrollTo: posTop });
	// }
};

const Routes = (props) => {
	const parentNode = useRef(null);
	const { t } = useTranslation();

	React.useEffect(() => {
		gsap.set(document.querySelector(".EM-logo"), {
			autoAlpha: 0,
			transform: "translateY(-20px)",
		});
		gsap.set(document.querySelector(".banner-title"), {
			autoAlpha: 0,
			transform: "translateY(-30px)",
		});

		gsap.set(document.querySelector(".banner-title-home-1"), {
			autoAlpha: 0,
			transform: "translateY(-30px)",
		});

		gsap.set(document.querySelector(".banner-title-home-2"), {
			autoAlpha: 0,
			transform: "translateY(-30px)",
		});

		gsap.set(document.querySelector("#videobutton"), {
			autoAlpha: 0,
			transform: "translateX(10px)",
		});

		gsap.set(document.querySelector(".load-estufas-icon"), {
			autoAlpha: 0,
			transform: "translateX(-20px)",
		});

		gsap.set(document.querySelector(".load-estruturas-icon"), {
			autoAlpha: 0,
			transform: "translateX(-20px)",
		});

		gsap.set(document.querySelector(".load-laser-icon"), {
			autoAlpha: 0,
			transform: "translateX(-20px)",
		});

		if (window.innerWidth > 850) {
			gsap.set(document.querySelector("nav"), {
				autoAlpha: 0,
				transform: "translateX(90px)",
			});
		} else {
			gsap.set(document.querySelector("nav"), {
				autoAlpha: 0,
				transform: "translateY(90px)",
			});
		}

		gsap.set(document.querySelector("#loading-screen"), {
			autoAlpha: 1,
		});

		//Animations TO

		//LOADING ANIMATION
		gsap.to(document.querySelector(".load-estufas-icon"), {
			duration: 0.5,
			autoAlpha: 1,
			transform: "none",
		});

		gsap.to(document.querySelector(".load-estruturas-icon"), {
			duration: 0.5,
			delay: 0.2,
			autoAlpha: 1,
			transform: "none",
		});

		gsap.to(document.querySelector(".load-laser-icon"), {
			duration: 0.5,
			delay: 0.4,
			autoAlpha: 1,
			transform: "none",
		});

		if (window.innerWidth > 850) {
			gsap.to(document.querySelector(".load-estufas-icon"), {
				duration: 0.5,
				delay: 1,
				transform: "translateX(166px)",
			});

			gsap.to(document.querySelector(".load-laser-icon"), {
				duration: 0.5,
				delay: 1,
				transform: "translateX(-166px)",
			});
		} else {
			gsap.to(document.querySelector(".load-estufas-icon"), {
				duration: 0.5,
				delay: 1,
				transform: "translateX(86px)",
			});

			gsap.to(document.querySelector(".load-laser-icon"), {
				duration: 0.5,
				delay: 1,
				transform: "translateX(-86px)",
			});
		}

		gsap.to(document.querySelector(".load-estufas-icon"), {
			duration: 0.3,
			delay: 1.5,
			autoAlpha: 0,
		});

		gsap.to(document.querySelector(".load-estruturas-icon"), {
			duration: 0.3,
			delay: 1.3,
			autoAlpha: 0,
		});

		gsap.to(document.querySelector(".load-laser-icon"), {
			duration: 0.3,
			delay: 1.3,
			autoAlpha: 0,
		});

		// gsap.set(document.querySelector(".load-em-icon"), {
		// 	delay: 2,
		// 	autoAlpha: 0,
		// 	width: "70px",
		// });
		// gsap.set(document.querySelector(".load-estruturas-icon"), {
		// 	delay: 2,
		// 	autoAlpha: 0,
		// 	width: "0",
		// });

		// gsap.to(document.querySelector(".load-em-icon"), {
		// 	duration: 0.7,
		// 	delay: 2,
		// 	autoAlpha: 1,
		// });

		// gsap.to(document.querySelector(".load-em-icon"), {
		// 	duration: 0.3,
		// 	delay: 2.7,
		// 	autoAlpha: 0,
		// });
		//END LOADING ANIMATION

		gsap.to(document.querySelector("nav"), {
			duration: 0.7,
			delay: 2,
			autoAlpha: 1,
			transform: "none",
			ease: "Power2.easeOut",
		});

		if (window.innerWidth > 850) {
			gsap.to(document.querySelector("#loading-screen"), {
				duration: 1,
				delay: 2,
				width: "0px",
				ease: "Power1.easeOut",
			});
		} else {
			gsap.to(document.querySelector("#loading-screen"), {
				duration: 1,
				delay: 2,
				height: "0px",
				ease: "Power1.easeOut",
			});
		}
		gsap.to(document.querySelector("#loading-screen"), {
			duration: 0.1,
			delay: 3,
			zIndex: "-1",
		});

		gsap.to(document.querySelector(".EM-logo"), {
			autoAlpha: 1,
			delay: 2.5,
			transform: "none",
			duration: 0.5,
		});
		gsap.to(document.querySelector(".banner-title"), {
			duration: 0.5,
			delay: 2.7,
			autoAlpha: 1,
			transform: "none",
		});

		gsap.to(document.querySelector(".banner-title-home-1"), {
			duration: 0.5,
			delay: 2.7,
			autoAlpha: 1,
			transform: "none",
		});

		gsap.to(document.querySelector(".banner-title-home-2"), {
			duration: 0.5,
			delay: 2.9,
			autoAlpha: 1,
			transform: "none",
		});

		gsap.to(document.querySelector("#videobutton"), {
			duration: 1,
			delay: 3.2,
			autoAlpha: 1,
			transform: "none",
		});
	}, []);

	const onEnterHandler = (node) => {
		gsap.killTweensOf(node);
		// Set initial position and styles
		gsap.set(node, {
			position: "absolute",
			left: 0,
			top: 0,
			autoAlpha: 0,
		});
		gsap.set(parentNode.current, { overflow: "hidden" });
		// Create the animation for the incoming component

		gsap.set(node.querySelector(".EM-logo"), {
			autoAlpha: 0,
			transform: "translateY(-20px)",
		});
		gsap.set(node.querySelector(".banner-title"), {
			autoAlpha: 0,
			transform: "translateY(-30px)",
		});

		gsap.set(document.querySelector(".banner-title-home-1"), {
			autoAlpha: 0,
			transform: "translateY(-30px)",
		});

		gsap.set(document.querySelector(".banner-title-home-2"), {
			autoAlpha: 0,
			transform: "translateY(-30px)",
		});

		gsap.set(document.querySelector("#videobutton"), {
			autoAlpha: 0,
			transform: "translateX(10px)",
		});

		gsap.to(node, {
			duration: 0.4,
			autoAlpha: 1,
			x: 0,
			onComplete: completeCall,
			onCompleteParams: [node, parentNode.current],
		});

		gsap.to(node.querySelector(".EM-logo"), {
			duration: 0.5,
			delay: 0.5,
			autoAlpha: 1,
			transform: "none",
		});
		gsap.to(node.querySelector(".banner-title"), {
			duration: 0.5,
			delay: 0.7,
			autoAlpha: 1,
			transform: "none",
		});

		gsap.to(document.querySelector(".banner-title-home-1"), {
			duration: 0.5,
			delay: 1,
			autoAlpha: 1,
			transform: "none",
		});

		gsap.to(document.querySelector(".banner-title-home-2"), {
			duration: 0.5,
			delay: 1.2,
			autoAlpha: 1,
			transform: "none",
		});

		gsap.to(document.querySelector("#videobutton"), {
			duration: 1,
			delay: 1.5,
			autoAlpha: 1,
			transform: "none",
		});
	};

	const onExitHandler = (node) => {
		// Set initial position and styles
		gsap.set(node.querySelector(".EM-logo"), {
			autoAlpha: 1,
			transform: "none",
		});

		gsap.set(node.querySelector(".banner-title"), {
			autoAlpha: 1,
			transform: "none",
		});

		gsap.set(node.querySelector(".banner-title-home-1"), {
			autoAlpha: 1,
			transform: "none",
		});

		gsap.set(node.querySelector(".banner-title-home-2"), {
			autoAlpha: 1,
			transform: "none",
		});

		gsap.set(node.querySelector("#videobutton"), {
			autoAlpha: 1,
			transform: "none",
		});
		// Create the animation for the incoming component
		gsap.to(node.querySelector(".EM-logo"), {
			duration: 0.4,
			autoAlpha: 0,
			transform: "translateY(-20px)",
		});

		gsap.to(node.querySelector(".banner-title"), {
			duration: 0.4,
			autoAlpha: 0,
			transform: "translateY(-30px)",
		});

		gsap.to(node.querySelector(".banner-title-home-1"), {
			duration: 0.4,
			autoAlpha: 0,
			transform: "translateY(-30px)",
		});

		gsap.to(node.querySelector(".banner-title-home-2"), {
			duration: 0.4,
			autoAlpha: 0,
			transform: "translateY(-30px)",
		});

		gsap.to(node.querySelector("#videobutton"), {
			duration: 0.4,
			autoAlpha: 0,
			transform: "translateY(-30px)",
		});

		gsap.set(node, {
			position: "absolute",
			left: 0,
			zIndex: -2,
		});

		gsap.to(node, {
			duration: 0.4,
			delay: 0.4,
			autoAlpha: 0,
		});

		gsap.killTweensOf(node);
	};

	const aos_settings = {
		"data-aos": "fade-up",
		"data-aos-duration": "500",
		"data-aos-delay": "0",
		"data-aos-anchor-placement": "bottom-bottom",
		"data-aos-easing": "ease-out-quad",
	};

	return (
		<React.Fragment>
			<Header />
			<div
				id="main-wrapper"
				className="w-100"
				ref={parentNode}
				style={{
					position: "relative",
					minHeight: "100vh",
				}}
			>
				<TransitionGroup component={null}>
					<Transition
						timeout={500}
						key={props.location.pathname}
						onEnter={onEnterHandler}
						onExit={onExitHandler}
					>
						<Switch location={props.location}>
							{/* <Route path="/">
								<ComingSoon />
							</Route> */}
							<Route path="/" exact>
								<Home />
							</Route>
							<Route path="/estufas">
								<Estufas aos_settings={aos_settings} />
							</Route>
							<Route path="/agropecuaria">
								<Agropecuaria aos_settings={aos_settings} />
							</Route>
							<Route path="/construcoes-metalicas">
								<ConstrucoesMetalicas
									aos_settings={aos_settings}
								/>
							</Route>
							<Route path="/perfis-metalicos">
								<PerfisMetalicos aos_settings={aos_settings} />
							</Route>
							<Route path="/corte-a-laser">
								<Corte aos_settings={aos_settings} />
							</Route>
							<Route path="/estruturas-fotovoltaicas">
								<Fotovoltaicas aos_settings={aos_settings} />
							</Route>
							<Route path="/contactos">
								<Contactos aos_settings={aos_settings} />
							</Route>
							<Route path="/sobre-nos">
								<SobreNos aos_settings={aos_settings} />
							</Route>
							<Route path="/politica-de-privacidade">
								<Privacidade aos_settings={aos_settings} />
							</Route>
							<Route path="/termos-e-condicoes">
								<Termos aos_settings={aos_settings} />
							</Route>
						</Switch>
					</Transition>
				</TransitionGroup>
			</div>

			<CookieConsent
				style={{ fontFamily: "acumin-pro, sans-serif" }}
				buttonStyle={{
					fontFamily: "acumin-pro, sans-serif",
					borderRadius: "15px",
					padding: "5px 30px",
					fontSize: "12px",
					backgroundColor: "transparent",
					border: "1px solid white",
					color: "white",
					fontWeight: "700",
					marginTop: "20px",
					marginBottom: "20px",
				}}
				location="top"
				buttonText={t("cookies.button-text")}
			>
				{t("cookies.uses-cookies")}{" "}
				<Link to="/politica-de-privacidade" target="_blank">
					{t("cookies.more-info-here")}
				</Link>
				.
			</CookieConsent>
		</React.Fragment>
	);
};

export default Routes;
