import React from "react";
import { Link } from "react-router-dom";
import "./Header.css";
import HeaderItem from "./HeaderItem";
// import HeaderItemPage from "./HeaderItemPage";
import HeaderItemPageFooter from "./HeaderItemPageFooter";

import { useTranslation } from "react-i18next";

import { ReactComponent as EMLogo } from "../../assets/logos/logo_em_black.svg";
import { ReactComponent as EstufasIcon } from "../../assets/logos/estufas.svg";
import { ReactComponent as EstruturasIcon } from "../../assets/logos/estruturas.svg";
import { ReactComponent as LaserIcon } from "../../assets/logos/laser.svg";

const Header = () => {
	const { t } = useTranslation();

	const [open, setOpen] = React.useState(false);

	// Close navbar on clicking elsewhere

	const closeNavBar = (e) => {
		document.querySelector("nav.nav-bar").classList.remove("active");
		setOpen(false);
	};
	document.querySelector("body").addEventListener("click", closeNavBar);

	/***
	 * Open and Show correct nav page when clicking on nav-items
	 */
	const onClick = (e) => {
		// console.log(e.target.classList);
		// console.log(e.target.parentElement.classList);
		// console.log(e.target.parentElement.parentElement.classList);
		if (
			e.target.classList.contains("nav-hamb-close") ||
			e.target.parentElement.classList.contains("nav-hamb-close") ||
			e.target.parentElement.parentElement.classList.contains(
				"nav-hamb-close"
			) ||
			e.target.parentElement.parentElement.parentElement.classList.contains(
				"nav-hamb-close"
			)
		) {
			setOpen(false);
			return;
		}
		setOpen(true);
		if (
			e.target.classList.contains("nav-item") ||
			e.target.classList.contains("nav-hamb") ||
			e.target.parentElement.classList.contains("nav-hamb") ||
			e.target.parentElement.parentElement.classList.contains(
				"nav-hamb"
			) ||
			e.target.parentElement.parentElement.parentElement.classList.contains(
				"nav-hamb"
			)
		) {
			let target = e.target.getAttribute("data-target");
			// console.log("target", target);
			document
				.querySelector(".nav-page.active")
				?.classList.remove("active");
			document.getElementById(target)?.classList.add("active");
		} else {
			let target = e.target.parentElement.getAttribute("data-target");
			document
				.querySelector(".nav-page.active")
				?.classList.remove("active");
			document.getElementById(target)?.classList.add("active");
		}
	};

	return (
		<nav
			className={`nav-bar ${open ? "active" : ""}`}
			onClick={(e) => {
				// stop propagation so it doesn't close inside navbar
				e.stopPropagation();
			}}
		>
			<div className="nav-menu">
				<HeaderItem
					classes="bc-white "
					dataTarget="nav-page-menu"
					onClick={onClick}
					open={open}
					isIcon={true}
				></HeaderItem>
				<HeaderItem
					classes="bc-white c-black nav-item-hide-mobile"
					dataTarget="nav-page-menu"
					onClick={(e) => onClick(e)}
					isIcon={false}
					link="/"
				>
					{t("general.menu")}
				</HeaderItem>
				<HeaderItem
					classes="bc-green c-white"
					dataTarget="nav-page-estufas"
					onClick={onClick}
					isIcon={false}
					link="/estufas"
				>
					{t("estufas.estufas")}
				</HeaderItem>
				<HeaderItem
					classes="bc-grey c-white"
					dataTarget="nav-page-estruturas"
					onClick={onClick}
					isIcon={false}
					link="/estruturas"
				>
					{t("estruturas.estruturas")} <br />
					{t("estruturas.metalicas")}
				</HeaderItem>
				<HeaderItem
					classes="bc-orange c-white"
					dataTarget="nav-page-corte"
					onClick={onClick}
					isIcon={false}
					link="/corte-a-laser"
				>
					{t("corte.corte")} <br />
					{t("corte.a-laser")}
				</HeaderItem>
			</div>
			<div className="nav-page-wrapper">
				<div className="nav-page bc-white active" id="nav-page-menu">
					<EMLogo className="page-icon hide-mobile" />
					<div>
						<h1 className="menu-title mb-2 mb-md-4">
							<Link to="/sobre-nos" onClick={closeNavBar}>
								{t("sobre-nos.sobre-nos")}
							</Link>
						</h1>
						<h1 className="menu-title mb-2 mb-md-4">
							<Link to="/estufas" onClick={closeNavBar}>
								{t("estufas.estufas")}
							</Link>
						</h1>
						<h1 className="menu-title mb-2 mb-md-4">
							<Link to="/agropecuaria" onClick={closeNavBar}>
								{t("agropecuaria.agropecuaria")}
							</Link>
						</h1>
						<h1 className="menu-title mb-2 mb-md-4">
							<Link
								to="/construcoes-metalicas"
								onClick={closeNavBar}
							>
								{t(
									"construcoes-metalicas.construcoes-metalicas"
								)}
							</Link>
						</h1>
						<h1 className="menu-title mb-2 mb-md-4">
							<Link to="/perfis-metalicos" onClick={closeNavBar}>
								{t("perfis-metalicos.perfis-metalicos")}
							</Link>
						</h1>
						<h1 className="menu-title mb-2 mb-md-4">
							<Link to="/corte-a-laser" onClick={closeNavBar}>
								{t("corte.corte-a-laser")}
							</Link>
						</h1>
					</div>
					<div>
						<Link
							to="/politica-de-privacidade"
							style={{ display: "block", marginBottom: "1rem" }}
							onClick={closeNavBar}
						>
							{t("general.politica-de-privacidade")}
						</Link>
						<a
							href="https://www.livroreclamacoes.pt/inicio"
							target="_blank"
							rel="noreferrer"
							style={{ display: "block", marginBottom: "1rem" }}
							onClick={closeNavBar}
						>
							{t("general.livro-de-reclamacoes")}
						</a>
						<Link
							to="/termos-e-condicoes"
							style={{ display: "block", marginBottom: "1rem" }}
							onClick={closeNavBar}
						>
							{t("general.termos-e-condicoes")}
						</Link>
					</div>

					<HeaderItemPageFooter
						textColorClass="c-black"
						onClick={closeNavBar}
					/>
				</div>
				<div
					className="nav-page bc-green c-white p-relative"
					id="nav-page-estufas"
				>
					<EstufasIcon className="page-icon hide-mobile" />
					<div className="w-100">
						<h1 className="menu-title mb-4 c-black">
							{t("estufas.estufas")}
						</h1>
						{/* <Link
							to="/estufas#horto-jardim"
							className="c-white"
							onClick={closeNavBar}
						>
							Horto Jardim
						</Link> */}
						<div className="row w-100">
							<div className="col-6 col-md-4">
								<p className="c-white mb-0 mb-md-2">
									{t("estufas.recta-planta-gotica")}
								</p>
								<p className="c-white mb-0 mb-md-2">
									{t("estufas.recta-planta-tropical")}
								</p>
								<p className="c-white mb-0 mb-md-2">
									{t("estufas.recta-fungi")}
								</p>
								<p className="c-white mb-0 mb-md-2">
									{t("estufas.recta-flor")}
								</p>
								<p className="c-white mb-0 mb-md-2">
									{t("estufas.garden-center")}
								</p>
							</div>
							<div className="col-6 col-md-4">
								<p className="c-white mb-0 mb-md-2">
									{t("estufas.horto-jardim")}
								</p>
								<p className="c-white mb-0 mb-md-2">
									{t("estufas.tunel")}
								</p>
								<p className="c-white mb-0 mb-md-2">
									{t("estufas.macrotunel")}
								</p>
								<p className="c-white mb-0 mb-md-2">
									{t("estufas.estufas-de-apoio")}
								</p>
								<p className="c-white mb-0 mb-md-2">
									{t("estufas.bancadas-modulares")}
								</p>
							</div>
						</div>
						<p className="c-white mb-0 mb-md-2">
							{t("estufas.recta-planta")}
						</p>

						<Link
							to="/estufas"
							className="btn btn-light btn-menu-link c-green"
							onClick={closeNavBar}
						>
							{t("actions.ver-mais")}
						</Link>

						<h1 className="menu-title mb-4 pt-4 pt-md-5 c-black">
							{t("agropecuaria.agropecuaria")}
						</h1>
						<p className="c-white mb-0 mb-md-2">
							{t("agropecuaria.equipamentos-de-apoio")}
						</p>
						<p className="c-white mb-0 mb-md-2">
							{t("agropecuaria.abrigo-animal")}
						</p>
						<Link
							to="/agropecuaria"
							className="btn btn-light btn-menu-link c-green"
							onClick={closeNavBar}
						>
							{t("actions.ver-mais")}
						</Link>
					</div>

					<HeaderItemPageFooter
						textColorClass="c-white"
						onClick={closeNavBar}
					/>
				</div>
				<div
					className="nav-page bc-grey c-white"
					id="nav-page-estruturas"
				>
					<EstruturasIcon className="page-icon hide-mobile" />
					<div className="pb-5">
						<h1 className="menu-title mb-4 c-white">
							{t("construcoes-metalicas.construcoes-metalicas")}
						</h1>

						<p className="c-white mb-0 mb-md-2">
							{t("construcoes-metalicas.producao")}
						</p>
						<p className="c-white mb-0 mb-md-2">
							{t("construcoes-metalicas.montagem")}
						</p>
						<p className="c-white mb-0 mb-md-2">
							{t(
								"construcoes-metalicas.aplicacao-de-revestimentos"
							)}
						</p>
						<Link
							to="/construcoes-metalicas"
							className="btn btn-light btn-menu-link  c-grey"
							onClick={closeNavBar}
						>
							{t("actions.ver-mais")}
						</Link>
						<div className="row">
							<div className="col-12 col-md-7">
								<h1 className="menu-title mb-4 pt-4 pt-md-3 c-white">
									{t("perfis-metalicos.perfis-metalicos")}
								</h1>
								<p className="c-white mb-0 mb-md-2">
									{t("perfis-metalicos.light-steel-frame")}
								</p>
								<p className="c-white mb-0 mb-md-2">
									{t("perfis-metalicos.enformados-a-frio")}
								</p>
								<Link
									to="/perfis-metalicos"
									className="btn btn-light btn-menu-link c-grey"
									onClick={closeNavBar}
								>
									{t("actions.ver-mais")}
								</Link>
							</div>
							<div className="col-12 col-md-5">
								<h1 className="menu-title mb-3 pt-4 pt-md-3 c-white">
									{t(
										"fotovoltaicas.estruturas-fotovoltaicas"
									)}
								</h1>
								<Link
									to="/estruturas-fotovoltaicas"
									className="btn btn-light btn-menu-link c-grey justify-self-end"
									onClick={closeNavBar}
								>
									{t("actions.ver-mais")}
								</Link>
							</div>
						</div>
					</div>

					<HeaderItemPageFooter
						textColorClass="c-white"
						onClick={closeNavBar}
					/>
				</div>
				<div className="nav-page bc-orange c-white" id="nav-page-corte">
					<LaserIcon className="page-icon hide-mobile" />
					<div>
						<h1 className="menu-title mb-4">Corte a laser</h1>
						<p className="c-white mb-0 mb-md-2">
							{t("corte.corte-de-tubo")}
						</p>

						<p className="c-white mb-0 mb-md-2">
							{t("corte.corte-de-chapa")}
						</p>
						<Link
							to="/corte-a-laser"
							className="btn btn-light btn-menu-link  c-orange"
							onClick={closeNavBar}
						>
							{t("actions.ver-mais")}
						</Link>
					</div>

					<HeaderItemPageFooter
						textColorClass="c-white"
						onClick={closeNavBar}
					/>
				</div>
			</div>
		</nav>
	);
};

export default Header;
