import React from "react";
import "./Hero.css";
import PageWrapper from "./PageWrapper";
import Banner from "../components/sections/Banner";
import bannerImage from "../assets/construcoes-metalicas.jpg";
// import { useTranslation } from "react-i18next";

const Termos = ({ children, aos_settings }) => {
	// const { t } = useTranslation();
	return (
		<PageWrapper bgColorClass="bc-grey-estruturas">
			<Banner isVideo={false} bannerImage={bannerImage} logoFill="white">
				Termos e Condições
			</Banner>
			<div className="page-content c-grey">
				<div className="mt-5 pt-5 container">
					<div className="row ">
						<div className="col-md-12 ">
							<div
								className="vlt-animated-block"
								data-aos="fade"
								data-aos-delay="100"
							>
								<h5>1. Termos</h5>

								<div className="mb-3"></div>
								<p>
									Ao aceder ao site{" "}
									<a href="https://www.estufasminho.pt">
										www.estufasminho.pt
									</a>{" "}
									, concorda em cumprir estes termos de
									serviço, todas as leis e regulamentos
									aplicáveis ​​e concorda que é responsável
									pelo cumprimento de todas as leis locais
									aplicáveis. Os materiais contidos neste site
									são protegidos pelas leis de direitos
									autorais e marcas comerciais aplicáveis.
								</p>
							</div>
							<div className="mb-5"></div>

							<div
								className="vlt-animated-block"
								data-aos="fade"
								data-aos-delay="100"
							>
								<h5>2. Uso de Licença</h5>

								<div className="mb-3"></div>
								<p>
									É concedida permissão para fazer
									temporariamente uma cópia dos materiais
									(informações ou software) no site{" "}
									<a href="https://www.estufasminho.pt">
										www.estufasminho.pt
									</a>
									, apenas para visualização transitória
									pessoal e não comercial. Esta é a concessão
									de uma licença, não uma transferência de
									título e, sob esta licença, você não pode:  
								</p>
								<ul>
									<li>modificar ou copiar os materiais; </li>
									<li>
										usar os materiais para qualquer
										finalidade comercial ou para exibição
										pública (comercial ou não comercial);{" "}
									</li>
									<li>
										fazer engenharia reversa de qualquer
										software contido no site Estufasminho
										S.A.;{" "}
									</li>
									<li>
										remover quaisquer direitos autorais ou
										outras notações de propriedade dos
										materiais; ou transferir os materiais
										para outra pessoa ou 'espelhe' os
										materiais em qualquer outro servidor.
									</li>
									<li>
										Esta licença será automaticamente
										rescindida se violar alguma destas
										restrições e poderá ser rescindida por
										Estufasminho S.A. a qualquer momento. Ao
										encerrar a visualização desses materiais
										ou após o término desta licença, deverá
										apagar todos os materiais baixados em
										sua posse, seja em formato eletrónico ou
										impresso.
									</li>
								</ul>
							</div>
							<div className="mb-5"></div>

							<div
								className="vlt-animated-block"
								data-aos="fade"
								data-aos-delay="100"
							>
								<h5>3. Isenção de responsabilidade</h5>

								<div className="mb-3"></div>
								<p>
									Os materiais no site da Estufasminho S.A.
									são fornecidos 'como estão'. A Estufasminho
									S.A. não oferece garantias, expressas ou
									implícitas, e, por este meio, isenta e nega
									todas as outras garantias, incluindo, sem
									limitação, garantias implícitas ou condições
									de comercialização, adequação a um fim
									específico ou não violação de propriedade
									intelectual ou outra violação de direitos.
								</p>
								<p>
									Além disso, a Estufasminho S.A. não garante
									ou faz qualquer representação relativa à
									precisão, aos resultados prováveis ​​ou à
									confiabilidade do uso dos materiais em seu
									site ou de outra forma relacionado a esses
									materiais ou em sites vinculados a este
									site.
								</p>
							</div>
							<div className="mb-5"></div>

							<div
								className="vlt-animated-block"
								data-aos="fade"
								data-aos-delay="100"
							>
								<h5>4. Limitações</h5>

								<div className="mb-3"></div>
								<p>
									Em nenhum caso a Estufasminho S.A. ou seus
									fornecedores serão responsáveis ​​por
									quaisquer danos (incluindo, sem limitação,
									danos por perda de dados ou lucro ou devido
									a interrupção dos negócios) decorrentes do
									uso ou da incapacidade de usar os materiais
									em Estufasminho S.A., mesmo que Estufas
									Minho S.A. ou um representante autorizado da
									Estufasminho S.A. tenha sido notificado
									oralmente ou por escrito da possibilidade de
									tais danos.{" "}
								</p>
							</div>
							<div className="mb-5"></div>

							<div
								className="vlt-animated-block"
								data-aos="fade"
								data-aos-delay="100"
							>
								<h5>5. Precisão dos materiais</h5>

								<div className="mb-3"></div>
								<p>
									Os materiais exibidos no site da Estufas
									Minho S.A. podem incluir erros técnicos,
									tipográficos ou fotográficos. A Estufas
									Minho S.A. não garante que qualquer material
									em seu site seja preciso, completo ou atual.
									A Estufasminho S.A. pode fazer alterações
									nos materiais contidos em seu site a
									qualquer momento, sem aviso prévio. No
									entanto, a Estufasminho S.A. não se
									compromete a atualizar os materiais.
								</p>
							</div>
							<div className="mb-5"></div>

							<div
								className="vlt-animated-block"
								data-aos="fade"
								data-aos-delay="100"
							>
								<h5>6. Links</h5>

								<div className="mb-3"></div>
								<p>
									O Estufasminho S.A. não analisou todos os
									sites vinculados ao seu site e não é
									responsável pelo conteúdo de nenhum site
									vinculado. A inclusão de qualquer link não
									implica endosso por Estufasminho S.A. do
									site. O uso de qualquer site vinculado é por
									conta e risco do usuário.
								</p>
							</div>
							<div className="mb-5"></div>

							<div
								className="vlt-animated-block"
								data-aos="fade"
								data-aos-delay="100"
							>
								<h5>7. Política de Privacidade e Cookies</h5>

								<div className="mb-3"></div>
								<p>
									A Estufasminho, S.A., utiliza tecnologia de
									pesquisa através de Cookies. A Política no
									uso de cookies está definida na Política de
									Cookies disponibilizada neste website.
									Clique <a href="./privacidade.html">aqui</a>{" "}
									para saber mais.
								</p>
								<p>
									Qualquer conteúdo ou dados pessoais que
									sejam enviados para o site{" "}
									<a href="https://www.estufasminho.pt">
										www.estufasminho.pt
									</a>{" "}
									estão sujeitos à Política de Privacidade e
									proteção de dados pessoais apresentados na
									Política de Privacidade{" "}
									<a href="./privacidade.html">aqui</a>.
								</p>
							</div>
							<div className="mb-5"></div>

							<div
								className="vlt-animated-block"
								data-aos="fade"
								data-aos-delay="100"
							>
								<h5>8. Modificações</h5>

								<div className="mb-3"></div>
								<p>
									A Estufasminho S.A. pode rever estes termos
									de serviço do site a qualquer momento, sem
									aviso prévio. Ao usar este site, você
									concorda em ficar vinculado à versão atual
									desses termos de serviço.
								</p>
							</div>
							<div className="mb-5"></div>

							<div
								className="vlt-animated-block"
								data-aos="fade"
								data-aos-delay="100"
							>
								<h5>9. Lei aplicável</h5>

								<div className="mb-3"></div>
								<p>
									Estes termos e condições são regidos e
									interpretados de acordo com as leis da
									Estufasminho S.A. e você se submete
									irrevogavelmente à jurisdição exclusiva dos
									tribunais em Portugal.
								</p>
							</div>

							<div className="mb-5--sm"></div>
						</div>
					</div>
				</div>
			</div>
		</PageWrapper>
	);
};

export default Termos;
