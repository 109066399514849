import React from "react";
import HamburgerIcon from "../elements/HamburgerIcon";

const HeaderItem = ({
	link = "",
	isIcon = false,
	classes,
	dataTarget,
	onClick,
	open,
	children,
}) => {
	const renderClassName = (isIcon) => {
		return isIcon
			? `nav-hamb d-flex justify-content-center ${
					open ? "nav-hamb-close " : ""
			  } `
			: "nav-item ";
	};
	const renderContent = (isIcon, link, children) => {
		if (isIcon) {
			return (
				<HamburgerIcon
					dataTarget={dataTarget}
					open={open}
					onClick={onClick}
				/>
			);
		} else {
			return <span className="nav-item-text">{children}</span>; //TODO check this for link
		}
	};
	return (
		<div
			className={
				renderClassName(isIcon) + (classes ? classes : "bc-white pt-3")
			}
			data-target={dataTarget}
			onClick={onClick}
			style={{
				cursor: "pointer",
			}}
		>
			{renderContent(isIcon, link, children)}
		</div>
	);
};

export default HeaderItem;
