import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const languages = ["pt", "en", "fr"];
i18n
	// load translation using http -> see /public/locales
	// learn more: https://github.com/i18next/i18next-http-backend
	.use(Backend)
	// detect user language
	// learn more: https://github.com/i18next/i18next-browser-languageDetector
	.use(LanguageDetector)
	// pass the i18n instance to react-i18next.
	.use(initReactI18next)
	.init({
		fallbackLng: "pt",
		lng: "pt",
		debug: true,
		whiteList: languages,

		interpolation: {
			escapeValue: false, // react already safes from xss
		},
	});

export default i18n;
