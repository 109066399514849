import React, { Suspense } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import Routes from "./routes/Routes";

import { ReactComponent as EstufasIcon } from "./assets/logos/estufas.svg";
import { ReactComponent as EstruturasIcon } from "./assets/logos/estruturas.svg";
import { ReactComponent as LaserIcon } from "./assets/logos/laser.svg";
import { ReactComponent as ReactLogo } from "./assets/logos/logo_em_black.svg";

//imagens
import videoSrc from "./assets/teste4K.mp4";
import bannerImageHome from "./assets/banner.png";
import bannerImageEstufas from "./assets/estufas/banner_1.jpg";
import imageSeparator1 from "./assets/estufas/banner_2.jpg";
import imageSeparator2 from "./assets/estufas/banner_3.jpg";
import imageSeparator3 from "./assets/estufas/banner_4.jpg";
import rectaPlantaTecnico from "./assets/rectaPlanta.png";
import rectaPlanta from "./assets/estufas/recta_planta.jpg";
import rectaPlantaGotica from "./assets/estufas/gotica.jpg";
import rectaPlantaTropical from "./assets/estufas/tropical.jpg";

import bannerImageConstrucoes from "./assets/construcoes-metalicas.jpg";
import imageConstrucoes3 from "./assets/IMG_3315.jpg";
import desenhoTecnicoSmall1 from "./assets/tre3.png";
import desenhoTecnicoSmall2 from "./assets/quatro4.png";
import desenhoTecnicoBig from "./assets/um1.png";
import fichaTecnicaFile from "./assets/banner.png";

const Loader = () => (
	<div
		id="loading-screen"
		className="d-flex bc-grey-estruturas justify-content-center align-items-center"
		style={{
			height: "100%",
			width: "100%",
			zIndex: "10",
			position: "fixed",
			right: "0",
			bottom: "0",
		}}
	>
		<div className="p-2 p-md-5" style={{ zIndex: "3" }}>
			<EstufasIcon
				className="load-estufas-icon"
				style={{ opacity: "0", zIndex: "3" }}
				width="70px"
			/>
		</div>
		<div className="p-2 p-md-5" style={{ zIndex: "2" }}>
			<EstruturasIcon
				className="load-estruturas-icon"
				style={{ opacity: "0" }}
				width="70px"
			/>
			<ReactLogo
				className="load-em-icon"
				style={{ opacity: "0" }}
				width="0"
			/>
		</div>
		<div className="p-2 p-md-5" style={{ zIndex: "1" }}>
			<LaserIcon
				className="load-laser-icon"
				style={{ opacity: "0" }}
				width="70px"
			/>
		</div>
	</div>
);

const App = () => {
	const [loadingImages, setLoadingImages] = React.useState(true);
	const [loadingVideos, setLoadingVideos] = React.useState(true);

	const cacheImages = async (srcArray) => {
		const promises = await srcArray.map((src) => {
			return new Promise(function (resolve, reject) {
				const img = new Image();

				img.src = src;
				img.onload = resolve();
				img.onerror = reject();
			});
		});

		// console.log("before");
		await Promise.all(promises);
		// console.log("after");

		setLoadingImages(false);
	};

	const cacheVideos = async (srcArray) => {
		const promises = await srcArray.map((src) => {
			return new Promise(function (resolve, reject) {
				const vid = document.createElement("video");
				// console.log(vid);
				vid.setAttribute("src", videoSrc);
				vid.onloadeddata = resolve();
				vid.onerror = reject();
			});
		});
		// console.log("before");
		await Promise.all(promises);
		// console.log("after");

		setLoadingVideos(false);
	};

	React.useEffect(() => {
		const images = [
			bannerImageHome,
			bannerImageEstufas,
			imageSeparator1,
			imageSeparator2,
			imageSeparator3,
			rectaPlantaTecnico,
			rectaPlanta,
			rectaPlantaGotica,
			rectaPlantaTropical,
			bannerImageConstrucoes,
			imageConstrucoes3,
			desenhoTecnicoSmall1,
			desenhoTecnicoSmall2,
			desenhoTecnicoBig,
			fichaTecnicaFile,
		];
		const videos = [videoSrc];
		cacheImages(images);
		cacheVideos(videos);
	}, []);
	return (
		<React.Fragment>
			{loadingImages === false && loadingVideos === false ? (
				<React.Fragment>
					<Loader />
					<Suspense fallback={<Loader />}>
						<Router>
							<Route path="/" component={Routes} />
						</Router>
					</Suspense>
				</React.Fragment>
			) : (
				<Loader />
			)}
		</React.Fragment>
	);
};

export default App;
