import React from "react";
import "./Hero.css";
import { useTranslation } from "react-i18next";

import bannerImage from "../assets/laser/banner.jpg";
import imageConstrucoes1 from "../assets/laser/image1.jpg";
import imageConstrucoes2 from "../assets/laser/image3.jpg";
import imageConstrucoes3 from "../assets/laser/image2.jpg";
// import fichaTecnicaFile from "../assets/banner.png";
import Banner from "../components/sections/Banner";
import WeirdSection from "../components/sections/WeirdSection";
import PageWrapper from "./PageWrapper";

const Corte = ({ children, aos_settings }) => {
	const { t } = useTranslation();
	return (
		<PageWrapper bgColorClass="bc-grey-estruturas pb-4 pb-md-5">
			<Banner isVideo={false} bannerImage={bannerImage} logoFill="white">
				{t("corte.corte-a-laser")}
			</Banner>
			<div className="page-content">
				<WeirdSection
					id="recta-planta"
					title={t("corte.corte-a-laser")}
					titleColorClass="c-grey"
					// fichaTecnicaFile={fichaTecnicaFile}
					// fichaTecnicaFileText={t("actions.download-brochura")}
					// isDownload={true}
					imageSmall={imageConstrucoes1}
					images={[imageConstrucoes2, imageConstrucoes3]}
					aos_settings={aos_settings}
					withGallery={true}
					customExtraContent={
						<React.Fragment>
							<div className="row">
								<div className="col-12 col-md-6">
									<h4 className="pb-3">
										{t("corte.especificacoes-tecnicas")}
									</h4>
									<h3>{t("corte.comprimento-das-barras")}</h3>
									<p>
										{t(
											"corte.comprimento-das-barras-texto"
										)}
									</p>
									<h3>{t("corte.seccao-maxima")}</h3>
									<p className="m-0">
										{t("corte.seccao-maxima-texto-1")}
									</p>
									<p className="m-0">
										{t("corte.seccao-maxima-texto-2")}
									</p>
									<p className="m-0 pb-4 pb-md-5 ">
										{t("corte.seccao-maxima-texto-3")}
									</p>
								</div>
								<div className="col-12 col-md-6">
									<h4 className="pb-3">
										{t("corte.servicos")}
									</h4>
									<p className="pr-5">
										{t("corte.servicos-texto")}
									</p>
								</div>
							</div>
						</React.Fragment>
					}
				>
					<p className="pb-4 pb-md-5" style={{ maxWidth: "75ch" }}>
						{t("corte.corte-a-laser-texto")}
					</p>
				</WeirdSection>
			</div>
		</PageWrapper>
	);
};

export default Corte;
