import React from "react";
import "./HamburgerIcon.css";

const HamburgerIcon = ({ open, onClick, dataTarget }) => {
	return (
		<React.Fragment>
			<div
				className={`menu-icon ${open ? "active" : ""}`}
				data-target={dataTarget}
			>
				<span data-target={dataTarget}></span>
				<span data-target={dataTarget}></span>
				<span data-target={dataTarget}></span>
			</div>
		</React.Fragment>
	);
};

export default HamburgerIcon;
