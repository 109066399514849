import React from "react";
import "./Hero.css";

import { useTranslation } from "react-i18next";

import bannerImage from "../assets/perfis/banner.jpg";
import imageConstrucoes2 from "../assets/perfis/Imagem-1.jpg";
import desenhoTecnicoSmall1 from "../assets/perfis/perfis2.jpg";
import desenhoTecnicoSmall2 from "../assets/perfis/perfis3.jpg";
import desenhoTecnicoSmall3 from "../assets/perfis/perfis4.jpg";
import desenhoTecnicoSmall4 from "../assets/perfis/perfis_detail1.jpg";
import desenhoTecnicoSmall5 from "../assets/perfis/perfis_detail2.jpg";
import desenhoTecnicoSmall6 from "../assets/perfis/perfis_detail3.jpg";
import desenhoTecnicoSmall7 from "../assets/perfis/perfis_detail4.jpg";
import gallery1 from "../assets/perfis/gallery1.jpg";
import gallery2 from "../assets/perfis/gallery2.jpg";
import gallery3 from "../assets/perfis/gallery1.jpg";
import gallery4 from "../assets/perfis/gallery4.jpg";
// import fichaTecnicaFile from "../assets/banner.png";
import Banner from "../components/sections/Banner";
import WeirdSection from "../components/sections/WeirdSection";
import PageWrapper from "./PageWrapper";
import WeirdTextSection from "../components/sections/WeirdTextSection";
import ThumbnailsDescriptionSection from "../components/sections/ThumbnailsDescriptionSection";

const PerfisMetalicos = ({ children, aos_settings }) => {
	const { t } = useTranslation();
	return (
		<PageWrapper bgColorClass="bc-grey-estruturas">
			<Banner isVideo={false} bannerImage={bannerImage} logoFill="white">
				{t("perfis-metalicos.perfis-metalicos")}
			</Banner>
			<div className="page-content c-grey">
				<WeirdSection
					id="light-steel-frame"
					title={t("perfis-metalicos.light-steel-frame")}
					titleColorClass="c-grey"
					// fichaTecnicaFile={fichaTecnicaFile}
					// fichaTecnicaFileText={t("actions.download-brochura")}
					// isDownload={true}
					// imageSmall={imageConstrucoes1}
					imageBig={imageConstrucoes2}
					aos_settings={aos_settings}
				>
					<h3 className="pb-4">{t("general.descricao")}:</h3>
					<p className="pb-4">
						{t("perfis-metalicos.light-steel-frame-descricao")}
					</p>
				</WeirdSection>
				<WeirdTextSection
					id="light-steel-frame-aplicacoes"
					leftTitle={t("general.aplicacoes")}
					leftText={t(
						"perfis-metalicos.light-steel-frame-aplicacoes"
					)}
					rightTitle="Características"
					rightText={
						<React.Fragment>
							<span className="medium-text">
								{t(
									"perfis-metalicos.light-steel-frame-caracteristicas-texto-1-1"
								)}
							</span>{" "}
							{t(
								"perfis-metalicos.light-steel-frame-caracteristicas-texto-1-2"
							)}
							<br />
							<span className="medium-text">
								{t(
									"perfis-metalicos.light-steel-frame-caracteristicas-texto-2-1"
								)}
							</span>{" "}
							{t(
								"perfis-metalicos.light-steel-frame-caracteristicas-texto-2-2"
							)}
							<br />
							<span className="medium-text">
								{t(
									"perfis-metalicos.light-steel-frame-caracteristicas-texto-3-1"
								)}
							</span>{" "}
							{t(
								"perfis-metalicos.light-steel-frame-caracteristicas-texto-3-2"
							)}
							<br />
							<span className="medium-text">
								{t(
									"perfis-metalicos.light-steel-frame-caracteristicas-texto-4-1"
								)}
							</span>{" "}
							{t(
								"perfis-metalicos.light-steel-frame-caracteristicas-texto-4-2"
							)}
							<br />
							<span className="medium-text">
								{t(
									"perfis-metalicos.light-steel-frame-caracteristicas-texto-5-1"
								)}
							</span>{" "}
							{t(
								"perfis-metalicos.light-steel-frame-caracteristicas-texto-5-2"
							)}
							<br />
							<span className="medium-text">
								{t(
									"perfis-metalicos.light-steel-frame-caracteristicas-texto-6-1"
								)}
							</span>{" "}
							{t(
								"perfis-metalicos.light-steel-frame-caracteristicas-texto-6-2"
							)}
							<br />
							<span className="medium-text">
								{t(
									"perfis-metalicos.light-steel-frame-caracteristicas-texto-7-1"
								)}
							</span>{" "}
							{t(
								"perfis-metalicos.light-steel-frame-caracteristicas-texto-7-2"
							)}
						</React.Fragment>
					}
					aos_settings={aos_settings}
				></WeirdTextSection>

				<ThumbnailsDescriptionSection
					nrItems={3}
					alignTextRight={false}
					image1={desenhoTecnicoSmall1}
					title1={t("perfis-metalicos.orificios")}
					text1={t("perfis-metalicos.orificios-descricao")}
					image2={desenhoTecnicoSmall2}
					title2={t("perfis-metalicos.oval")}
					text2={t("perfis-metalicos.oval-descricao")}
					image3={desenhoTecnicoSmall3}
					title3={t("perfis-metalicos.triangular")}
					text3={t("perfis-metalicos.triangular-descricao")}
					aos_settings={aos_settings}
				/>

				<WeirdSection
					id="eformados-a-frio"
					title={
						<React.Fragment>
							{t("perfis-metalicos.enformados")}
							<br />
							{t("perfis-metalicos.a-frio")}
						</React.Fragment>
					}
					titleColorClass="c-grey"
					// fichaTecnicaFile={fichaTecnicaFile}
					// fichaTecnicaFileText={t("actions.download-brochura")}
					// isDownload={true}
					// imageSmall={imageConstrucoes1}
					images={[gallery1, gallery2, gallery3, gallery4]}
					// imageBig={imageConstrucoes2}
					aos_settings={aos_settings}
					withGallery={true}
				>
					<h4 className="pb-4 pb-md-5" style={{ maxWidth: "50ch" }}>
						{t("perfis-metalicos.enformados-a-frio-descricao")}
					</h4>
					<h3 className="pb-4">{t("general.aplicacoes")}</h3>
					<ul className="pb-4 pb-md-5" style={{ maxWidth: "90ch" }}>
						<li>
							{t(
								"perfis-metalicos.enformados-a-frio-aplicacoes-texto-1"
							)}
						</li>
						<li>
							{t(
								"perfis-metalicos.enformados-a-frio-aplicacoes-texto-2"
							)}
						</li>
						<li>
							{t(
								"perfis-metalicos.enformados-a-frio-aplicacoes-texto-3"
							)}
						</li>
						<li>
							{t(
								"perfis-metalicos.enformados-a-frio-aplicacoes-texto-4"
							)}
						</li>
						<li>
							{t(
								"perfis-metalicos.enformados-a-frio-aplicacoes-texto-5"
							)}
						</li>
					</ul>
				</WeirdSection>

				<ThumbnailsDescriptionSection
					nrItems={4}
					title={t("perfis-metalicos.perfis")}
					textAlign="right"
					image1={desenhoTecnicoSmall4}
					text1="C200"
					image2={desenhoTecnicoSmall5}
					text2="Ómega 110"
					image3={desenhoTecnicoSmall6}
					text3="U66x54"
					image4={desenhoTecnicoSmall7}
					text4="L50"
					aos_settings={aos_settings}
				/>
			</div>
		</PageWrapper>
	);
};

export default PerfisMetalicos;
