import React from "react";
import "./WeirdSection.css";

const WeirdTextSection = ({
	children,
	id,
	title,
	titleColorClass,
	description,
	leftTitle = false,
	leftText = false,
	rightTitle = false,
	rightText = false,
	aos_settings,
	withGallery = false,
}) => {
	return (
		<React.Fragment>
			<div
				className="row pt-4 pt-md-5 pr-3 pr-md-0 pl-3 pl-md-5 pb-4 pb-md-5 h-100 justify-content-between align-items-stretch"
				id={id}
			>
				<div className="col-12 col-md-4 pt-4 pt-md-5 pb-4 pb-md-5">
					<div className="">
						<h3 className="pb-3" {...aos_settings}>
							{leftTitle ?? ""}
						</h3>
						<p {...aos_settings} style={{ maxWidth: "80ch" }}>
							{leftText ?? ""}
						</p>
					</div>
				</div>
				<div className="col-12 col-md-7 col-sm-12 pt-4 pt-md-5">
					<div className="">
						<h3 className=" pb-3" {...aos_settings}>
							{rightTitle ?? ""}
						</h3>
						<p {...aos_settings} style={{ maxWidth: "70ch" }}>
							{rightText ?? ""}
						</p>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default WeirdTextSection;
