import React from "react";
import "./Hero.css";
import { useTranslation } from "react-i18next";

import bannerImage from "../assets/fotovoltaicas/banner.jpg";
import imageConstrucoes1 from "../assets/fotovoltaicas/worker.jpg";
import imageConstrucoes2 from "../assets/fotovoltaicas/drone.jpg";
// import fichaTecnicaFile from "../assets/banner.png";
import Banner from "../components/sections/Banner";
import WeirdSection from "../components/sections/WeirdSection";
import PageWrapper from "./PageWrapper";

const Fotovoltaicas = ({ children, aos_settings }) => {
	const { t } = useTranslation();
	return (
		<PageWrapper bgColorClass="bc-grey-estruturas pb-4 pb-md-5">
			<Banner isVideo={false} bannerImage={bannerImage} logoFill="white">
				{t("fotovoltaicas.estruturas-fotovoltaicas")}
			</Banner>
			<div className="page-content">
				<WeirdSection
					id="estruturas-fotovoltaicas"
					title={t("fotovoltaicas.estruturas-fotovoltaicas")}
					titleColorClass="c-grey"
					// fichaTecnicaFile={fichaTecnicaFile}
					// fichaTecnicaFileText={t("actions.download-brochura")}
					// isDownload={true}
					imageSmall={imageConstrucoes1}
					imageBig={imageConstrucoes2}
					aos_settings={aos_settings}
					withGallery={false}
					customExtraContent={
						<React.Fragment>
							<div className="row">
								<div className="col-12 col-md-6">
									<h4 className="pb-3" {...aos_settings}>
										{t("fotovoltaicas.universo-aplicacao")}
									</h4>
									<p {...aos_settings}>
										{t(
											"fotovoltaicas.universo-aplicacao-texto"
										)}
									</p>
								</div>
								<div className="col-12 col-md-6">
									<h4 className="pb-3" {...aos_settings}>
										{t("fotovoltaicas.tipologias")}
									</h4>
									<p className="pr-5" {...aos_settings}>
										{t("fotovoltaicas.tipologias-texto-1")}
									</p>
									<p className="pr-5" {...aos_settings}>
										{t("fotovoltaicas.tipologias-texto-2")}
									</p>
									<p className="pr-5" {...aos_settings}>
										{t("fotovoltaicas.tipologias-texto-3")}
									</p>
								</div>
							</div>
						</React.Fragment>
					}
				>
					<div className="pb-4 pb-md-5">
						<p style={{ maxWidth: "75ch" }} {...aos_settings}>
							{t("fotovoltaicas.texto-1")}
						</p>
						<p style={{ maxWidth: "75ch" }} {...aos_settings}>
							{t("fotovoltaicas.texto-2")}
						</p>
						<p style={{ maxWidth: "75ch" }} {...aos_settings}>
							{t("fotovoltaicas.texto-3")}
						</p>
					</div>
				</WeirdSection>
			</div>
		</PageWrapper>
	);
};

export default Fotovoltaicas;
