import React from "react";

const ImageWithLegend = ({
	children,
	image,
	backgroundColorClass,
	legend = false,
	animation = false,
	aos_settings,
}) => {
	return (
		<React.Fragment>
			<div className="row flex-md-row pt-4 pt-md-5 p-3 pb-4 pb-md-5">
				<div className="col-12 col-md-9 pt-4 pt-md-5 pl-3 pl-md-5">
					<div className="container-img-effect multiply">
						<div className={`${animation ? "img-reveal" : ""}`}>
							<img
								className="image-left-to-right"
								src={image}
								alt=""
								style={{
									mixBlendMode: "multiply",
									maxWidth: "100%",
									objectFit: "cover",
								}}
							/>
						</div>
					</div>
				</div>
				{legend ? (
					<div className="col-12 col-md-3 pr-3 pr-md-5 d-flex align-items-start pt-md-0 pt-3">
						<div style={{ position: "sticky", top: "20px" }}>
							{legend}
						</div>
					</div>
				) : (
					""
				)}
			</div>
		</React.Fragment>
	);
};

export default ImageWithLegend;
