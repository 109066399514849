import React from "react";
import "./Hero.css";

import { useTranslation } from "react-i18next";

import bannerImage from "../assets/construcoes-metalicas.jpg";
import imageConstrucoes1 from "../assets/IMG_2220.jpg";
import imageConstrucoes2 from "../assets/IMG_4893.jpg";
// import imageConstrucoes3 from "../assets/IMG_3315.jpg";
// import galleryImage1 from "../assets/estruturas/gallery1.JPG";
import galleryImage2 from "../assets/estruturas/gallery2.jpg";
import galleryImage3 from "../assets/estruturas/gallery3.jpg";
import galleryImage4 from "../assets/estruturas/gallery4.jpg";
import galleryImage5 from "../assets/estruturas/gallery5.jpg";
// import galleryImage6 from "../assets/estruturas/gallery6.jpg";
import galleryImage7 from "../assets/estruturas/gallery7.jpg";
// import galleryImage8 from "../assets/estruturas/gallery8.jpg";
import galleryImage9 from "../assets/estruturas/gallery9.jpg";
// import desenhoTecnicoSmall1 from "../assets/tre3.png";
// import desenhoTecnicoSmall2 from "../assets/quatro4.png";
// import desenhoTecnicoBig from "../assets/um1.png";
import desenhoTecnicoSmall1 from "../assets/estruturas/image1.png";
import desenhoTecnicoSmall2 from "../assets/estruturas/image2.png";
import desenhoTecnicoBig from "../assets/estruturas/image3.png";
import fichaTecnicaFile from "../assets/estruturas/estruturas-metalicas.pdf";
import Banner from "../components/sections/Banner";
import WeirdSection from "../components/sections/WeirdSection";
import GallerySection from "../components/sections/GallerySection";
import ThreeImageSeparator from "../components/sections/ThreeImageSeparator";
import PageWrapper from "./PageWrapper";

const ConstrucoesMetalicas = ({ children, aos_settings }) => {
	const { t } = useTranslation();
	return (
		<PageWrapper bgColorClass="bc-grey-estruturas">
			<Banner isVideo={false} bannerImage={bannerImage} logoFill="white">
				{t("construcoes-metalicas.construcoes-metalicas")}
			</Banner>
			<div className="page-content c-grey">
				<WeirdSection
					id="experiencia-e-profissionalismo"
					title={t(
						"construcoes-metalicas.experiencia-e-profissionalismo"
					)}
					titleColorClass="c-grey"
					constructionsTitle={t(
						"construcoes-metalicas.equipamentos-e-solucoes"
					)}
					constructions={t(
						"construcoes-metalicas.equipamentos-e-solucoes-texto"
					)}
					fichaTecnicaFile={fichaTecnicaFile}
					fichaTecnicaFileText={t("actions.download-brochura")}
					isDownload={true}
					imageSmall={imageConstrucoes1}
					imageBig={imageConstrucoes2}
					aos_settings={aos_settings}
				>
					<p className="pb-4 pb-md-5" style={{ maxWidth: "60ch" }}>
						{t(
							"construcoes-metalicas.experiencia-e-profissionalismo-texto-1"
						)}
					</p>
					<ul
						className="pt-4 pt-md-5 pb-4 pb-md-5"
						style={{ maxWidth: "60ch" }}
					>
						<li>
							{t(
								"construcoes-metalicas.experiencia-e-profissionalismo-texto-2"
							)}
						</li>
						<li>
							{t(
								"construcoes-metalicas.experiencia-e-profissionalismo-texto-3"
							)}
						</li>
					</ul>
				</WeirdSection>

				<ThreeImageSeparator
					id="desenho-tecnico"
					imageSmall1={desenhoTecnicoSmall1}
					imageSmall2={desenhoTecnicoSmall2}
					imageBig={desenhoTecnicoBig}
					aos_settings={aos_settings}
					bgColorClass="bc-grey-estruturas"
				/>
				<GallerySection
					id="projectos-selecionados"
					title={t("construcoes-metalicas.projectos-seleccionados")}
					titleColorClass="c-grey"
					buttonLink="/contactos"
					buttonText={t("actions.contacte-nos")}
					images={[
						// galleryImage1,
						galleryImage2,
						galleryImage3,
						galleryImage4,
						galleryImage5,
						// galleryImage6,
						galleryImage7,
						// galleryImage8,
						galleryImage9,
					]}
					aos_settings={aos_settings}
				></GallerySection>
			</div>
		</PageWrapper>
	);
};

export default ConstrucoesMetalicas;
