import React from "react";
import { Link } from "react-router-dom";

const TitlePlusButton = ({
	children,
	id,
	noHeight100 = false,
	title,
	titleColorClass,
	titleH3 = false,
	buttonLink,
	buttonText,
	smallTitle = false,
	smallTitleColorClass = false,
	isDownload = false,
	positionSticky = true,
	customContent = false,
	aos_settings,
}) => {
	const renderButtonHtml = () => {
		return buttonText && buttonLink ? (
			<Link
				className="custom-download-button"
				to={buttonLink}
				{...(isDownload
					? { target: "_black", download: "download" }
					: {})}
				{...aos_settings}
			>
				{buttonText}
			</Link>
		) : (
			""
		);
	};

	const renderSmallTitleHtml = () => {
		return smallTitle ? (
			<p
				className={`${smallTitleColorClass}`}
				{...aos_settings}
				style={{ maxWidth: "70ch" }}
			>
				{smallTitle}
			</p>
		) : (
			""
		);
	};

	const renderTitleHtml = () => {
		return titleH3 ? (
			<h4 {...aos_settings} className={`pb-4 pb-md-5 ${titleColorClass}`}>
				{title}
			</h4>
		) : (
			<h1 {...aos_settings} className={`pb-4 pb-md-5 ${titleColorClass}`}>
				{title}
			</h1>
		);
	};

	const renderCustomContent = () => {
		return customContent ? customContent : "";
	};
	return (
		<div
			className={`${noHeight100 ? "" : "h-100"}`}
			style={{ position: "relative" }}
		>
			<div
				style={
					positionSticky ? { position: "sticky", top: "20px" } : {}
				}
			>
				{renderTitleHtml()}
				{renderSmallTitleHtml()}
				{renderButtonHtml()}
				{renderCustomContent()}
			</div>
		</div>
	);
};

export default TitlePlusButton;
