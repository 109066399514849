import React from "react";
import "./ProductSection.css";
import "../elements/SimpleSlider";

import SimpleSlider from "../elements/SimpleSlider";
import TitlePlusButton from "../elements/TitlePlusButton";

const GallerySection = ({
	children,
	id,
	title,
	titleColorClass,
	buttonLink,
	buttonText,
	images,
	noTitle = false,
}) => {
	const renderHtml = () => {
		return noTitle ? (
			<React.Fragment>
				<div className="col-12 col-md-7 col-sm-12 pt-4 pt-md-5">
					<div className="w-100 pt-4 pt-md-5 pb-4 pb-md-5">
						<SimpleSlider images={images} />
						{/* <img src={image} className="img-remove-blend w-100" /> */}
					</div>
				</div>
			</React.Fragment>
		) : (
			<React.Fragment>
				<div className="col-12 col-md-3 pt-4 pt-md-5 pb-4 pb-md-5">
					<div className="h-100 d-flex flex-column justify-content-between align-items-stretch">
						<TitlePlusButton
							titleColorClass={titleColorClass}
							title={title}
							buttonLink={buttonLink}
							buttonText={buttonText}
							isDownload={false}
						/>
					</div>
				</div>
				<div className="col-12 col-md-7 col-sm-12 pt-4 pt-md-5">
					<div className="w-100 pt-4 pt-md-5 pb-4 pb-md-5">
						<SimpleSlider images={images} />
						{/* <img src={image} className="img-remove-blend w-100" /> */}
					</div>
				</div>
			</React.Fragment>
		);
	};

	return (
		<React.Fragment>
			<div
				className={`row pt-4 pt-md-5 pr-3 pl-3 pl-md-5 pr-md-0 pb-4 pb-md-5 h-100 ${
					noTitle
						? "justify-content-center"
						: "justify-content-between"
				} align-items-stretch`}
				id={id}
			>
				{renderHtml()}
			</div>
		</React.Fragment>
	);
};

export default GallerySection;
