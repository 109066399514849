import React from "react";

const ImageSeparator = ({ children, image, parallax = false }) => {
	return parallax ? (
		<div
			className="w-100 pt-4 pt-md-5 pb-4 pb-md-5 mt-5 mb-5 image-separator-wrapper parallax-section"
			style={{
				position: "relative",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<div
				className="parrallax-bg-image-container"
				style={{
					backgroundImage: `url("${image}")`,
					backgroundRepeat: "no-repeat",
					backgroundSize: "cover",
					backgroundPosition: "center",
					position: "absolute",
					top: "0",
					left: "0",
					width: "100%",
					height: "100%",
				}}
			></div>
			<div className="container-fluid h-100 w-100">
				<div className="h-100 w-100 d-flex flex-column align-items-end justify-content-center p-5">
					<h1
						className="c-white"
						style={{
							color: "#92BB8B",
							fontSize: "50px",
							fontWeight: 500,
							zIndex: "1",
						}}
					>
						{children}
					</h1>
				</div>
			</div>
		</div>
	) : (
		<div
			className="w-100 pt-5 pb-5 mt-5 mb-5 image-separator-wrapper"
			style={{
				backgroundImage: `url("${image}")`,
				backgroundRepeat: "no-repeat",
				backgroundSize: "cover",
			}}
		>
			<div className="container-fluid h-100 w-100">
				<div className="h-100 w-100 d-flex flex-column align-items-end justify-content-center p-5">
					<h1
						className="c-white"
						style={{
							color: "#92BB8B",
							fontSize: "50px",
							fontWeight: 500,
						}}
					>
						{children}
					</h1>
				</div>
			</div>
		</div>
	);
};

export default ImageSeparator;
