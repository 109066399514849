import React from "react";
import "./WeirdSection.css";
import "../elements/TitlePlusButton";
// import { gsap } from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";

import TitlePlusButton from "../elements/TitlePlusButton";

import SimpleSlider from "../elements/SimpleSlider";

// gsap.registerPlugin(ScrollTrigger);

const WeirdSection = ({
	children,
	id,
	title,
	titleColorClass,
	firstColWide = false,
	titleH3 = false,
	positionSticky = true,
	smallTitle = false,
	smallTitleColorClass = false,
	description,
	constructionsTitle = false,
	constructions = false,
	customExtraContent = false,
	imageSmall = false,
	imageBig = false,
	images = false,
	fichaTecnicaFile = false,
	fichaTecnicaFileText = false,
	isDownload = false,
	aos_settings,
	withGallery = false,
}) => {
	return (
		<React.Fragment>
			<div
				className="row pt-4 pt-md-5 pr-3 pr-md-0 pl-3 pl-md-5 pb-4 pb-md-5 h-100 justify-content-between align-items-stretch weird-section-container"
				id={id}
			>
				<div
					className={`col-12 ${
						firstColWide ? "col-md-4" : "col-md-3"
					} pt-4 pt-md-5 pb-4 pb-md-5`}
				>
					<div className="h-100 d-flex flex-column justify-content-between align-items-stretch">
						<div className="h-100 pb-4 pb-md-5">
							<TitlePlusButton
								title={title}
								titleColorClass={titleColorClass}
								titleH3={titleH3}
								smallTitle={smallTitle}
								smallTitleColorClass={smallTitleColorClass}
								positionSticky={positionSticky}
								buttonText={fichaTecnicaFileText}
								buttonLink={fichaTecnicaFile}
								isDownload={isDownload}
								aos_settings={aos_settings}
							/>
						</div>
						{imageSmall ? (
							<div className="w-100 pb-4 pb-md-5">
								<div className="container-img-effect">
									<div className="img-reveal">
										<img
											alt=""
											src={imageSmall}
											className={`img-remove-blend image-left-to-right ${
												firstColWide ? "" : "w-100"
											}`}
											{...(firstColWide
												? { style: { width: "75%" } }
												: {})}
										/>
									</div>
								</div>
							</div>
						) : (
							""
						)}
					</div>
				</div>
				<div className="col-12 col-md-7 col-sm-12 pt-4 pt-md-5">
					<div>
						<div {...aos_settings} className="pr-5">
							{children}
						</div>
					</div>

					<div className="w-100 pt-4 pt-md-5 pb-4 pb-md-5">
						{withGallery ? (
							<SimpleSlider images={images} />
						) : (
							<div className="container-img-effect">
								<div className="img-reveal">
									<img
										alt=""
										src={imageBig}
										className="img-remove-blend w-100 image-left-to-right"
									/>
								</div>
							</div>
						)}
					</div>

					{constructionsTitle || constructions ? (
						<div className="pt-4 pt-md-5 ">
							<div className="pt-4 pt-md-5">
								<h3
									className="pt-4 pt-md-5 pb-3"
									{...aos_settings}
								>
									{constructionsTitle}
								</h3>
								<p
									{...aos_settings}
									style={{ maxWidth: "70ch" }}
								>
									{constructions}
								</p>
							</div>
						</div>
					) : (
						""
					)}

					{customExtraContent ?? ""}
				</div>
			</div>
		</React.Fragment>
	);
};

export default WeirdSection;
