import React, { useState } from "react";
import "./Hero.css";

import { useTranslation } from "react-i18next";

import bannerImage from "../assets/contact/banner.jpg";
import Banner from "../components/sections/Banner";
import PageWrapper from "./PageWrapper";

import { useForm } from "react-hook-form";
import TitleSection from "../components/sections/TitleSection";

// import GoogleMapReact from "google-map-react";

const Contactos = ({ children, aos_settings }) => {
	const { t } = useTranslation();
	const [status, setStatus] = useState(t("contactos.enviar"));
	const [sentMessage, setSentMessage] = useState("");

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const onSubmit = async (data) => {
		setStatus(t("contactos.a-enviar"));
		let details = {
			name: data.name,
			email: data.email,
			telemovel: data.telemovel,
			subject: data.subject,
			message: data.message,
		};
		let response = await fetch("https://estufasminho.pt/api/contact", {
			method: "POST",
			headers: {
				"Content-Type": "application/json;charset=utf-8",
			},
			body: JSON.stringify(details),
		});
		setStatus(t("contactos.enviar"));
		let result = await response.json();
		if (result.code === "200") {
			setSentMessage(t("contactos.enviado"));
		} else {
			setSentMessage(t("contactos.erro"));
		}
		setTimeout(() => {
			setSentMessage("");
		}, 2000);
	};

	// const defaultProps = {
	// 	center: {
	// 		lat: 59.95,
	// 		lng: 30.33,
	// 	},
	// 	zoom: 11,
	// };

	return (
		<PageWrapper bgColorClass="bc-grey-estruturas">
			<Banner isVideo={false} bannerImage={bannerImage} logoFill="white">
				{t("general.contactos")}
			</Banner>
			<div className="page-content">
				<div className="row justify-content-between">
					<div className="col-12 col-md-5 p-5">
						<div className="ContactForm">
							<div className="container">
								<div className="row">
									<div className="col-12 text-right">
										<div className="contactForm">
											<form
												id="contact-form"
												onSubmit={handleSubmit(
													onSubmit
												)}
												noValidate
											>
												<div className="row formRow">
													<div className="col">
														<h5>
															{t(
																"contactos.contact-form"
															)}
														</h5>
													</div>
												</div>
												{/* Row 1 of form */}
												<div className="row formRow">
													<div className="col">
														<label for="name">
															{t(
																"contactos.name"
															)}
														</label>
														<input
															type="text"
															name="name"
															{...register(
																"name",
																{
																	required: {
																		value: true,
																		message:
																			t(
																				"contactos.name-message"
																			),
																	},
																	maxLength: {
																		value: 30,
																		message:
																			t(
																				"contactos.name-valid-message"
																			),
																	},
																}
															)}
															className="form-control formInput"
															placeholder={t(
																"contactos.name"
															)}
														></input>
														{errors.name && (
															<span className="errorMessage">
																{
																	errors.name
																		.message
																}
															</span>
														)}
													</div>
												</div>
												<div className="row formRow">
													<div className="col">
														<label for="email">
															{t(
																"contactos.email"
															)}
														</label>
														<input
															type="email"
															name="email"
															{...register(
																"email",
																{
																	required: true,
																	pattern:
																		/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
																}
															)}
															className="form-control formInput"
															placeholder={t(
																"contactos.email"
															)}
														></input>
														{errors.email && (
															<span className="errorMessage">
																{t(
																	"contactos.email-valid-message"
																)}
															</span>
														)}
													</div>
												</div>
												<div className="row formRow">
													<div className="col">
														<label for="telemovel">
															{t(
																"contactos.telemovel"
															)}
														</label>
														<input
															type="text"
															name="telemovel"
															{...register(
																"telemovel",
																{
																	required: true,
																	pattern:
																		/^[0-9]*$/,
																}
															)}
															className="form-control formInput"
															placeholder={t(
																"contactos.telemovel"
															)}
														></input>
														{errors.telemovel && (
															<span className="errorMessage">
																{t(
																	"contactos.telemovel-valid-message"
																)}
															</span>
														)}
													</div>
												</div>
												{/* Row 2 of form */}
												<div className="row formRow">
													<div className="col">
														<label for="subject">
															{t(
																"contactos.subject"
															)}
														</label>
														<input
															type="text"
															name="subject"
															{...register(
																"subject",
																{
																	required: {
																		value: true,
																		message:
																			t(
																				"contactos.subject-message"
																			),
																	},
																	maxLength: {
																		value: 75,
																		message:
																			t(
																				"contactos.subject-valid-message"
																			),
																	},
																}
															)}
															className="form-control formInput"
															placeholder={t(
																"contactos.subject"
															)}
														></input>
														{errors.subject && (
															<span className="errorMessage">
																{
																	errors
																		.subject
																		.message
																}
															</span>
														)}
													</div>
												</div>
												{/* Row 3 of form */}
												<div className="row formRow">
													<div className="col">
														<label for="message">
															{t(
																"contactos.message"
															)}
														</label>
														<textarea
															rows={10}
															name="message"
															{...register(
																"message",
																{
																	required: true,
																}
															)}
															className="form-control formInput"
															placeholder={t(
																"contactos.message"
															)}
														></textarea>
														{errors.message && (
															<span className="errorMessage">
																{t(
																	"contactos.message-message"
																)}
															</span>
														)}
													</div>
												</div>
												<button
													className="submit-btn"
													type="submit"
												>
													{status}
												</button>
												<p>{sentMessage}</p>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-12 col-md-6">
						<TitleSection
							title={t("contactos.como-podemos-ajudar")}
							noHeight100={true}
							customContent={
								<React.Fragment>
									<h3>{t("contactos.email")}</h3>
									<a
										className="p pb-3 pb-md-4 "
										style={{ display: "block" }}
										href="mailto:geral@estufasminho.pt"
									>
										geral@estufasminho.pt
									</a>
									<h3>{t("contactos.telefone")}</h3>
									<a
										className="p pb-3 pb-md-4 "
										style={{ display: "block" }}
										href="tel:+351253989360"
									>
										+351 253 989 360<sup>*</sup>
									</a>
									<h3>{t("contactos.telemovel")}</h3>
									<a
										className="p pb-3 pb-md-4 "
										style={{ display: "block" }}
										href="tel:+351912552999"
									>
										+351 912 552 999<sup>**</sup>
									</a>
									<h3>{t("contactos.redes-sociais")}</h3>
									<a
										className="p pb-3 pb-md-4 "
										style={{ display: "block" }}
										target="_blank"
										rel="noreferrer"
										npm
										href="https://www.facebook.com/estufasminho"
									>
										Facebook
									</a>
									<a
										className="p pb-3 pb-md-4 "
										style={{ display: "block" }}
										target="_blank"
										rel="noreferrer"
										href="https://www.instagram.com/estufasminho"
									>
										Instagram
									</a>
									<a
										className="p pb-3 pb-md-4 "
										style={{ display: "block" }}
										target="_blank"
										rel="noreferrer"
										href="https://www.linkedin.com/estufasminho"
									>
										Linkedin
									</a>

									<p className="p" style={{marginTop:"2rem", marginBottom:"0", fontSize:"13px"}}><sup>*</sup>Chamada para rede fixa nacional.</p>
									<p className="p" style={{marginTop:"0", fontSize:"13px"}}><sup>**</sup>Chamada para rede móvel nacional.</p>
								</React.Fragment>
							}
						/>
						<div
							className="row pr-3 pr-md-5 pl-3 pl-md-5 pb-4 pb-md-5"
							style={{ minHeight: "20vh" }}
						>
							<div className="col-12">
								<div className="w-100 ">
									<div className="mapouter">
										<div className="gmap_canvas">
											<iframe
												width="100%"
												height="400px"
												id="gmap_canvas"
												title="address"
												src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2987.914985509939!2d-8.758660984157135!3d41.50611707925358!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd24495b00e2fc0f%3A0x4391d395120e46c2!2sEstufasminho%2C%20S.A.!5e0!3m2!1sen!2spt!4v1627573633542!5m2!1sen!2spt"
												frameborder="0"
												scrolling="no"
												marginheight="0"
												marginwidth="0"
												loading="lazy"
											></iframe>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</PageWrapper>
	);
};

export default Contactos;
