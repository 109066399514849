// import React, { useState } from "react";
import React from "react";
import videoSrc from "../assets/teste4K.mp4";
import videoBigSrc from "../assets/EM_video_final.mp4";
import bannerImage from "../assets/banner.png";
import PageWrapper from "./PageWrapper";
import Banner from "../components/sections/Banner";

// import "../../node_modules/react-modal-video/css/modal-video.min.css";

// import ModalVideo from "react-modal-video";

import { useTranslation } from "react-i18next";

const Home = ({ children, aos_settings }) => {
	const { t } = useTranslation();

	const openVideo = () => {
		document.getElementById("videosec").style.display = "block";
		const video = document.getElementById("my-video");
		video.play();
		video.currentTime = 0;
	};

	const closeVideo = () => {
		document.getElementById("videosec").style.display = "none";
		const video = document.getElementById("my-video");
		video.pause();
		video.currentTime = 0;
	};
	// const [isOpen, setIsOpen] = useState(false);

	// const openModal = () => {
	// 	setIsOpen(true);
	// };

	// const closeModal = () => {
	// 	setIsOpen(false);
	// };
	return (
		<PageWrapper bgColorClass="bc-green">
			<Banner
				isVideo={true}
				bannerImage={bannerImage}
				videoSrc={videoSrc}
				logoFill="white"
			>
				<h3 className="c-white banner-title-home-1">
					{`${t("estufas.estufas")} . ${t(
						"estruturas.estruturas-metalicas"
					)} . ${t("corte.corte-a-laser")}`}
				</h3>
				<h1 className="c-white banner-title-home-2">
					{t("home.projectamos-espacos")} ,
					<br /> {t("home.concebemos-solucoes-de-futuro")}
				</h1>
				<p
					id="videobutton"
					className="btn btn-light"
					onClick={openVideo}
					style={{
						borderRadius: "15px",
						padding: "5px 30px",
						fontSize: "12px",
						backgroundColor: "transparent",
						border: "1px solid white",
						color: "white",
						fontWeight: "700",
						marginTop: "20px",
						marginBottom: "20px",
					}}
				>
					{t("general.ver-video-completo")}
				</p>
			</Banner>
			<div
				className="container"
				style={{
					maxWidth: "calc(100vw - 100px)",
				}}
			>
				{/* <ModalVideo
					channel="youtube"
					autoplay
					isOpen={isOpen}
					videoId="L61p2uyiMSo"
					onClose={closeModal}
				/>

				<button className="btn-primary" onClick={openModal}>
					VIEW DEMO
				</button> */}
			</div>
			<div id="videosec">
				<p id="closevideo" onClick={closeVideo}>
					X
				</p>
				<video
					id="my-video"
					className="video-js vjs-fill"
					controls
					playsInline
					preload="auto"
					poster="MY_VIDEO_POSTER.jpg"
					data-setup="{}"
				>
					<source src={videoBigSrc} type="video/mp4" />
				</video>
			</div>
		</PageWrapper>
	);
};

export default Home;
