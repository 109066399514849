import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const HeaderItemPageFooter = ({ textColorClass, onClick }) => {
	const { t, i18n } = useTranslation();

	const changeLanguage = (language) => {
		i18n.changeLanguage(language);
	};

	React.useEffect(() => {
		if (
			!i18n.language !== "pt" &&
			!i18n.language !== "en" &&
			!i18n.language !== "fr"
		) {
			changeLanguage("pt");
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className="d-flex justify-content-between w-100 pb-4">
			{/* <div className="d-flex align-items-end">
				<p
					className={`menu-link ${
						i18n.language === "pt" ? "active" : ""
					} ${textColorClass}`}
					onClick={() => {
						changeLanguage("pt");
					}}
				>
					PT
				</p>
				<p
					className={`menu-link ml-5 ${
						i18n.language === "en" ? "active" : ""
					} ${textColorClass}`}
					onClick={() => {
						changeLanguage("en");
					}}
				>
					EN
				</p>
				<p
					className={`menu-link ml-5 ${
						i18n.language === "fr" ? "active" : ""
					} ${textColorClass}`}
					onClick={() => {
						changeLanguage("fr");
					}}
				>
					FR
				</p>
			</div> */}
			<div>
				<Link
					to="/contactos"
					className={`h3 d-block  ${textColorClass}`}
					onClick={onClick}
				>
					{t("general.contactos")}
				</Link>
				<p
					className={`font-weight-light hide-mobile ${textColorClass}`}
				>
					{t("general.contactos-description-1")}{" "}
					{t("general.contactos-description-2")}
				</p>
			</div>
		</div>
	);
};

export default HeaderItemPageFooter;
